import { hasBaselineProduct, isBaselineProduct } from '~modules/product';
import { patientProgressAtom, profileProgressAtom } from '~modules/state';
import {
  ALL_SET_PAGE_NAME,
  CONSENT_SIGNATURE_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  INSURANCE_ID_PAGE_NAME,
  PATIENT_LOGIN_PAGE_NAME,
} from '~types';

// Cyclic dependency
import { patientHistoryProgressWithShortCircuit } from '../services/patientHistoryProgress';
import { historyRoutes } from './history';

import type { BaseParamsWithoutRouting, RoutingConfig, StackParamList } from '~types';
import type { RecoilState } from 'recoil';

const processPatientProgress = async (
  getState: <T>(recoilValue: RecoilState<T>) => Promise<T>,
  searchParams: BaseParamsWithoutRouting,
  currentStep: 'login' | 'consent' | 'creditCard' | 'insuranceId'
) => {
  const { products, consent } = await getState(patientProgressAtom);
  const { isInsuranceAccepted, hasInsuranceFrontCard } = await getState(profileProgressAtom);
  const pendingProducts = products?.filter(p => p.status === 'pending') ?? [];
  const noProductsPurchased = !products || products.length === 0;

  // 'login'
  switch (currentStep) {
    case 'login':
      if (!consent) {
        return { name: CONSENT_SIGNATURE_PAGE_NAME };
      }

    // break omitted
    case 'consent':
      // To remove, once any skip credit card links have been consumed.
      // Can probably remove within 2 weeks of launching this to production.
      if (isBaselineProduct(searchParams.product) && !hasBaselineProduct(products)) {
        return { name: CREDIT_CARD_PAGE_NAME };
      }

      // Can also remove the `noProductsPurchased` check as well, when the above branch is removed
      if (pendingProducts.length > 0 || noProductsPurchased) {
        return { name: CREDIT_CARD_PAGE_NAME };
      }

    // break omitted
    case 'creditCard':
      if (isInsuranceAccepted && !hasInsuranceFrontCard) {
        return { name: INSURANCE_ID_PAGE_NAME };
      }

    // break omitted
    case 'insuranceId':
    default:
      if (currentStep !== 'login') {
        return historyRoutes;
      }

      // If current step is login, that means that we didn't see any pages yet.
      // So, route to historyRoutes with short circuiting.
      return patientHistoryProgressWithShortCircuit(getState, historyRoutes);
  }
};

export const postBookingRoutes = {
  id: 'postBooking' as const,
  initial: () => ({ name: PATIENT_LOGIN_PAGE_NAME }),
  routes: {
    [PATIENT_LOGIN_PAGE_NAME]: {
      reset: true,
      on: {
        signin: (getState, searchParams) => {
          return processPatientProgress(getState, searchParams, 'login');
        },
      },
    },
    [CONSENT_SIGNATURE_PAGE_NAME]: {
      on: {
        submit: (getState, searchParams) => {
          return processPatientProgress(getState, searchParams, 'consent');
        },
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: (getState, searchParams) => {
          return processPatientProgress(getState, searchParams, 'creditCard');
        },
      },
    },
    [INSURANCE_ID_PAGE_NAME]: {
      on: {
        submit: (getState, searchParams) => {
          return processPatientProgress(getState, searchParams, 'insuranceId');
        },
      },
    },
    [ALL_SET_PAGE_NAME]: {
      reset: true,
      on: null,
    },
  },
} satisfies RoutingConfig<StackParamList>;
