import React, { useCallback, useEffect, useState } from 'react';

import { cypressUtilities } from '@almond/utils';

import { patientsApi } from '~modules/api';
import { useRouteNavigation } from '~modules/routing';
import { infectionMessage, TypeformWidget } from '~modules/typeform';

import { MockWidget } from './MockWidget';

import type { INFECTION_QUESTIONNAIRE_PAGE_NAME } from '~types';
import type { z } from 'zod';

const CURATED_BOOKING_TYPEFORM_ID = 'f9wO6H39';
const IS_PROD = process.env.EXPO_PUBLIC_ENV === 'prod';
const USE_MOCK_WIDGET = process.env.EXPO_PUBLIC_MOCK_TYPEFORM === 'true' || cypressUtilities.isCypressRunning();

export const InfectionQuestionnairePage: React.FC = () => {
  const { dispatch } = useRouteNavigation<typeof INFECTION_QUESTIONNAIRE_PAGE_NAME>();
  const [message, setMessage] = useState<z.infer<typeof infectionMessage> | undefined>();

  const saveResponse = useCallback(async () => {
    if (!message) return;

    if (message.outcome === 'reviewing' || message.outcome === 'urgentCare') {
      await patientsApi.sendSystemMessage('infection_questionnaire_completion');
    }

    await dispatch(message.outcome);
  }, [dispatch, message]);

  const onMessage = useCallback(
    async (iframeMessage: z.infer<typeof infectionMessage>) => {
      setMessage(iframeMessage);
    },
    [setMessage]
  );

  useEffect(() => {
    if (!message) return;

    saveResponse();
  }, [message, saveResponse]);

  return (
    <TypeformWidget
      testID="infectionQuestionnairePage"
      isProd={IS_PROD}
      id={CURATED_BOOKING_TYPEFORM_ID}
      onMessage={onMessage}
      schema={infectionMessage}
      MockWidget={USE_MOCK_WIDGET ? MockWidget : undefined}
    />
  );
};
