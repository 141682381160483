import React from 'react';

import { useTheme } from '@almond/ui';

import LocalImage from '../LocalImage';

import { messageImageStyles } from './styles.ts';

import type { Images } from '~assets';
import type { ImageStyle, StyleProp } from 'react-native';

export type ImageDimensions = { width: number; height: number };
export type ImagePosition = 'top' | 'right';

export type MessageImageProps = {
  source: keyof Images;
  dimensions: ImageDimensions;
  hiddenCoefficient: number;
  style?: StyleProp<ImageStyle>;
  isMobile: boolean;
  position: ImagePosition;
};

const getPositionStyle = (
  position: ImagePosition,
  dimensions: ImageDimensions,
  hiddenCoefficient: number
): ImageStyle => {
  if (position === 'top') return { marginBottom: -(dimensions.height * hiddenCoefficient) };
  if (position === 'right') return { marginLeft: -(dimensions.width * hiddenCoefficient) };

  throw new Error(`MessageImage doesn't support ${position} position.`);
};

export const MessageImage = (props: MessageImageProps) => {
  const { source, dimensions, position, hiddenCoefficient, style, isMobile } = props;
  const [styles] = useTheme(messageImageStyles);

  return (
    <LocalImage
      source={source}
      style={[
        isMobile ? styles.containerMobile : styles.containerDesktop,
        dimensions,
        getPositionStyle(position, dimensions, hiddenCoefficient),
        style,
      ]}
    />
  );
};
