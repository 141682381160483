import { useRecoilValue } from 'recoil';

import { patientProgressAtom } from '~modules/state';

import { isMembershipProduct } from '../services';
import { useStripeProduct } from './useStripeProduct';

import type { MembershipTypesEnum } from '@almond/api-types';

export const useStripeMembership = (): MembershipTypesEnum | null => {
  const { product } = useStripeProduct();
  const { subscription, subscriptionActive } = useRecoilValue(patientProgressAtom);

  if (subscription?.membershipType && subscriptionActive) {
    return subscription.membershipType;
  }

  const key = product?.almondProductKey;

  if (isMembershipProduct(key)) {
    return key;
  }

  return null;
};
