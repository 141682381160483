import React, { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { COMPLETED_DURING_CURRENT_SESSION } from '@almond/utils';
import { useFocusEffect, useLocalSearchParams } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { useRedirectBackToArtemis } from '~modules/integration';
import { InterstitialLayout } from '~modules/layout';
import { useStripeProduct } from '~modules/product';
import { useIntakeFormToComplete, useRouteNavigation } from '~modules/routing';
import { patientProgressAtom } from '~modules/state';

import { IntakeFormNotFound } from '../../components';

import type { INTAKE_FORM_INTRO_PAGE_NAME } from '~types';

export const IntakeFormIntroPage = () => {
  const { t } = useTranslation();
  const { formType } = useLocalSearchParams();
  const { allProducts } = useStripeProduct();
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);
  const { dispatch } = useRouteNavigation<typeof INTAKE_FORM_INTRO_PAGE_NAME>();

  const { backToArtemisLinkProps } = useRedirectBackToArtemis();

  const currentIntakeForm = useIntakeFormToComplete(formType);

  useFocusEffect(
    useCallback(() => {
      // When arriving on an intake intro page, make sure that the current form is not marked as completed.
      // This way, we can progress to the form on the next page without the getIntakeFormsToComplete()
      // logic thinking that the form has already been completed.
      // Only do this for forms completed during the current session.
      setPatientProgressState(prev => ({
        ...prev,
        intakeForms: prev.intakeForms?.filter(
          form => form.createdAt !== COMPLETED_DURING_CURRENT_SESSION || form.formType !== formType
        ),
      }));
    }, [formType, setPatientProgressState])
  );

  if (!currentIntakeForm) {
    return <IntakeFormNotFound />;
  }

  const product = allProducts?.find(p => p.almondProductKey === currentIntakeForm.product);
  const isMsq = currentIntakeForm.type === 'msq';
  const titleTranslationKey = isMsq ? 'intakeFormIntro.msqTitle' : 'intakeFormIntro.title';
  const descriptionTranslationKey = isMsq ? 'intakeFormIntro.msqDescription' : 'intakeFormIntro.description';
  const submitTranslationKey = isMsq ? 'intakeFormIntro.msqSubmit' : 'intakeFormIntro.submit';

  return (
    <InterstitialLayout
      id="intakeFormIntro"
      title={t(titleTranslationKey, { productName: product?.name })}
      description={t(descriptionTranslationKey, { productName: product?.name })}
      submitButtonProps={{
        onPress: () => {
          dispatch('submit');
        },
        children: t(submitTranslationKey),
      }}
      secondaryButtonProps={{
        ...backToArtemisLinkProps(),
        children: t('intakeFormIntro.cancel'),
      }}
    />
  );
};
