import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ConnectedTextInput, Text, useTheme } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { getPatientHistoryProgress, useRouteNavigation } from '~modules/routing';
import { accountSetupAtom, patientProgressAtom } from '~modules/state';
import { NEXT_STEPS_PAGE_NAME, type REFERRAL_PAGE_NAME, type ReferralFormValues } from '~types';

import { useAddReferral } from '../../hooks';

import { themedStyles } from './styles';

import type { FormValues } from '@almond/ui';

export const ReferralPage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync, isLoading } = useAsync();
  const addReferral = useAddReferral();
  const { dispatch } = useRouteNavigation<typeof REFERRAL_PAGE_NAME>();
  const [styles] = useTheme(themedStyles);
  const patientProgressState = useRecoilValue(patientProgressAtom);
  const isComplete = useMemo(() => {
    const nextPage = getPatientHistoryProgress({ ...patientProgressState, referral: true });

    return nextPage.name === NEXT_STEPS_PAGE_NAME;
  }, [patientProgressState]);

  const handleSubmit = useCallback(
    (values: FormValues<ReferralFormValues>) => {
      const toCall = async (): Promise<void> => {
        await addReferral(values);
        await dispatch('submit');
      };

      doAsync(toCall);
    },
    [addReferral, dispatch, doAsync]
  );

  return (
    <JotaiForm atom={accountSetupAtom} onSubmit={handleSubmit} isLoading={isLoading}>
      <Container
        id="referral"
        title={t('referral.title')}
        size="M"
        footer={
          <FormFooter
            submitButtonTitle={isComplete ? t('referral.finishTitle') : t('continue')}
            requiredFields={['referral']}
          />
        }
      >
        <View style={styles.container}>
          <View style={styles.input}>
            <Text size="m">{t('referral.referralTitle')}</Text>
            <ConnectedTextInput name="referral" placeholder={t('referral.referralPlaceholder')} testID="Referral" />
          </View>
          <View style={styles.input}>
            <Text size="m">{t('referral.referrerByNameTitle')}</Text>
            <ConnectedTextInput
              name="referrerByName"
              placeholder={t('referral.referrerByNamePlaceholder')}
              testID="ReferrerByName"
            />
          </View>
        </View>
      </Container>
    </JotaiForm>
  );
};
