/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { View } from 'react-native';

import { FemaleDoctorIcon, MaterialIcon, ReproductiveIcon, useTheme } from '@almond/ui';

import { Calendar } from '~assets';

import { filtersStyles as themedStyles } from './styles';

export const Filters = () => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.container}>
      <Filter icon={<ReproductiveIcon width={20} />} />
      <Filter icon={<MaterialIcon source="camera" size={20} color="secondaryTextDark" />} />
      <Filter icon={<FemaleDoctorIcon width={20} />} />
      <Filter icon={<Calendar width={20} />} />
    </View>
  );
};

type FilterProps = {
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

const Filter = ({ icon, children }: FilterProps) => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.filter}>
      {icon}
      {children}
    </View>
  );
};
