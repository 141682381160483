import type { StringifiedBoolean } from './navigationWithoutRouting';

export const CASH_RATES_PAGE_NAME = '/cash-rates' as const;
export const CREDIT_CARD_PAGE_NAME = '/credit-card' as const;
export const DEMOGRAPHIC_PAGE_NAME = '/demographic' as const;
export const INSURANCE_PAGE_NAME = '/insurance' as const;
export const INSURANCE_ID_PAGE_NAME = '/insurance-id' as const;
export const INSURANCE_NOT_ACCEPTED_PAGE_NAME = '/insurance-not-accepted' as const;
export const PRICING_EXPLANATION_PAGE_NAME = '/pricing-explanation' as const;
export const PHONE_VERIFICATION_PAGE_NAME = '/phone-verification' as const;

export type CreditCardParams = { error?: string };
export type InsuranceIdParams = {
  can_skip?: StringifiedBoolean;
  should_hide_close_button?: StringifiedBoolean;
};

export type ProfileParams = {
  [CASH_RATES_PAGE_NAME]: undefined;
  [CREDIT_CARD_PAGE_NAME]: CreditCardParams;
  [DEMOGRAPHIC_PAGE_NAME]: undefined;
  [INSURANCE_PAGE_NAME]: undefined;
  [INSURANCE_ID_PAGE_NAME]: InsuranceIdParams;
  [INSURANCE_NOT_ACCEPTED_PAGE_NAME]: undefined;
  [PRICING_EXPLANATION_PAGE_NAME]: undefined;
  [PHONE_VERIFICATION_PAGE_NAME]: undefined;
};
