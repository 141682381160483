import React from 'react';

import { useTranslation } from '@almond/localization';
import { Text } from '@almond/ui';
import { formatPriceInCents, parseStrikethrough } from '@almond/utils';

import { ModalButton } from '../ModalButton';

import type { BaseButtonProps } from '@almond/ui';

export type JoinAlmondButtonProps = Omit<BaseButtonProps, 'children'> & {
  totalPrice: number;
  discountedPrice: number;
};

export const JoinAlmondButton = (props: JoinAlmondButtonProps) => {
  const { style, totalPrice, discountedPrice, ...restProps } = props;
  const { t } = useTranslation();
  const pricePerMonth = formatPriceInCents(discountedPrice / 12, true);

  return (
    <>
      <ModalButton {...restProps}>
        {parseStrikethrough(
          t('notSureAboutCostModal.joinAlmondFor', {
            oldPrice: formatPriceInCents(totalPrice, true),
            newPrice: formatPriceInCents(discountedPrice, true),
          })
        )}
      </ModalButton>
      <Text size="m">{t('notSureAboutCostModal.equivalentTo', { price: pricePerMonth })}</Text>
    </>
  );
};
