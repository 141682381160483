import React, { useEffect, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useGlobalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { FEEDBACK_PATH, feedbackApi } from '~modules/api';
import { useAsync } from '~modules/promises';
import { patientAtom, profileProgressAtom, useWaitlistModalState } from '~modules/state';

import { useRedirectBackToArtemis } from '../../hooks';
import { logError } from '../../logger';
import { AbandonModal } from '../AbandonModal';
import { AdditionalOfferModal } from '../AdditionalOfferModal';
import { ConfirmationModal } from '../ConfirmationModal';
import { FeedbackModal } from '../FeedbackModal';
import { FirstMonthFreeModal } from '../FirstMonthFreeModal';

type ActiveModal = {
  id: 'abandon' | 'feedback' | 'confirmation' | 'firstMonthFree' | 'additionalOffer' | 'waitlist';
  onBeforeSubmit?: () => Promise<string>;
};

export type AbandonModalWrapperProps = {
  isVisible: boolean;
  onRequestClose: () => void;
  onLeave: () => void;
};

export const AbandonModalWrapper = (props: AbandonModalWrapperProps) => {
  const { isVisible, onRequestClose, onLeave } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal>({ id: 'abandon' });
  const [feedbackUuid, setFeedbackUuid] = useState<string>();
  const { doAsync } = useAsync();
  const { redirectBackToArtemis } = useRedirectBackToArtemis();
  const { t } = useTranslation();
  const { patientUuid, isNewMember } = useRecoilValue(patientAtom);
  const { isPricingPageViewed } = useRecoilValue(profileProgressAtom);
  const [waitlistState, waitlistDispatch] = useWaitlistModalState();
  const searchParams = useGlobalSearchParams();
  const waitlistIsAllowed = Boolean(searchParams.visit_reason && !searchParams.booking_uuid);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    waitlistDispatch({ type: activeModal.id === 'waitlist' ? 'open' : 'close' });
  }, [activeModal.id, isVisible, waitlistDispatch]);

  // Once waitlist modal is closed, close the AbandonModal
  useEffect(() => {
    if (activeModal.id === 'waitlist' && !waitlistState.isVisible) {
      setActiveModal({ id: 'abandon' });
      onRequestClose();
    }
  }, [activeModal.id, waitlistState.isVisible, onRequestClose]);

  if (!isVisible) return null;

  const handlePress = (key: string, text: string) => {
    const toCall = async () => {
      const patientResponse: { text: string; patientUuid?: string } = { text };

      if (!isNewMember) {
        patientResponse.patientUuid = patientUuid;
      }

      try {
        const response = await feedbackApi.createFeedback()({
          eventType: 'abandon',
          response: patientResponse,
        });

        setFeedbackUuid(response.uuid);

        if (key === 'somethingElse') {
          setActiveModal({ id: 'feedback' });
        } else if (key === 'contactUs') {
          onRequestClose();
          redirectBackToArtemis('/messages');
        } else if (key === 'notSure' && !isPricingPageViewed) {
          setActiveModal({ id: 'firstMonthFree' });
        } else if (key === 'notSure' && isPricingPageViewed) {
          setActiveModal({ id: 'additionalOffer' });
        } else if (key === 'noDays' && waitlistIsAllowed) {
          setActiveModal({ id: 'waitlist' });
        } else {
          setActiveModal({ id: 'confirmation' });
        }
      } catch (err) {
        if (key === 'somethingElse') {
          setActiveModal({
            id: 'feedback',
            onBeforeSubmit: async () => {
              const response = await feedbackApi.createFeedback()({
                eventType: 'abandon',
                response: patientResponse,
              });

              return response.uuid;
            },
          });
        } else {
          // Don't block user from completing their action if the API request fails, but log
          // a useful error to Sentry
          logError(`Call to abandon ${FEEDBACK_PATH} failed`, `Payload: ${JSON.stringify(patientResponse)}`);
        }
      }
    };

    doAsync(toCall);
  };

  const handleRequestClose = () => {
    setActiveModal({ id: 'abandon' });
    onRequestClose();
  };

  const feedbackModalUuid = feedbackUuid || activeModal.onBeforeSubmit;

  return (
    <>
      <AbandonModal
        isVisible={activeModal.id === 'abandon'}
        onRequestClose={handleRequestClose}
        onPress={handlePress}
      />
      {feedbackModalUuid && (
        <FeedbackModal
          isVisible={activeModal.id === 'feedback'}
          onRequestClose={handleRequestClose}
          feedbackUuid={feedbackModalUuid}
          onSubmit={() => setActiveModal({ id: 'confirmation' })}
          eventType="abandon"
          onBack={() => setActiveModal({ id: 'abandon' })}
        />
      )}
      <FirstMonthFreeModal
        isVisible={activeModal.id === 'firstMonthFree'}
        onRequestClose={handleRequestClose}
        onBack={() => setActiveModal({ id: 'abandon' })}
      />
      <AdditionalOfferModal
        isVisible={activeModal.id === 'additionalOffer'}
        onRequestClose={handleRequestClose}
        onBack={() => setActiveModal({ id: 'abandon' })}
      />
      <ConfirmationModal
        isVisible={activeModal.id === 'confirmation'}
        onRequestClose={handleRequestClose}
        onPress={() => {
          setActiveModal({ id: 'abandon' });
          onLeave();
        }}
        submitTitle={t(isNewMember ? 'backToWebsite' : 'takeMeHome')}
      />
    </>
  );
};
