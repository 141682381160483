/* eslint-disable max-lines */
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FlatList, View } from 'react-native';

import { BaseButton, PulseProvider, SENTRY_UNMASK, useTheme } from '@almond/ui';
import { useEvent, useTimeout } from '@almond/utils';
import dayjs from 'dayjs';

import { ChevronRight } from '~assets';

import { Day, LoadingDay } from './Day';
import { useKeyboardNavigation } from './useKeyboardNavigation';
import { useScrollControls } from './useScrollControls';
import { useScrollToDate } from './useScrollToDate';

import { calendarBarStyles, DAY_WIDTH, DAY_WIDTH_MARGIN_RIGHT } from './styles';

import type { Dayjs } from 'dayjs';
import type { ViewToken } from 'react-native';

export { DisabledCalendarBarOld } from './DisabledCalendarBar';

export type CalendarBarInstanceOld = {
  scrollToDate: (date: Dayjs) => void;
};

const getItemLayout = (_: unknown, index: number) => {
  const length = DAY_WIDTH + DAY_WIDTH_MARGIN_RIGHT;

  return { length, offset: length * index, index };
};

type CalendarBarProps = {
  isAllLoading?: boolean;
  selectedDate: Dayjs;
  onSelectDate: (date: Dayjs) => void;
  dateStatus: (date: Dayjs) => boolean | null;
  onVisibleDateChange: (startVisibleDate: Dayjs, endVisibleDate: Dayjs) => void;

  // TODO dynamically update how many days are visible, or detect how many are visible
  // based on the viewport width
  visibleDays: number;
};

export const CalendarBarOld = forwardRef<CalendarBarInstanceOld, CalendarBarProps>(function CalendarBar(props, ref) {
  const { isAllLoading, selectedDate, onSelectDate, dateStatus, onVisibleDateChange, visibleDays } = props;
  const [styles] = useTheme(calendarBarStyles);
  const scrollContainerRef = useRef<FlatList<Dayjs>>(null);
  const viewableItemsRef = useRef<Pick<ViewToken, 'index' | 'item'>[]>([]);
  const preventInitialScrollRef = useRef<boolean>(false);
  const scrollLeftButtonRef = useRef<View>(null);
  const scrollRightButtonRef = useRef<View>(null);
  const [setTimeout] = useTimeout();
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  const [renderedDateStart] = useState(() => {
    const now = dayjs();

    return selectedDate.isBefore(now) ? selectedDate : now;
  });
  const [renderedDateEnd, setRenderedDateEnd] = useState(selectedDate.add(Math.floor(visibleDays * 2), 'days'));

  const scrollToDate = useScrollToDate(
    scrollContainerRef,
    renderedDateStart,
    renderedDateEnd,
    setRenderedDateEnd,
    visibleDays
  );
  const { scrollLeft, scrollRight } = useScrollControls(scrollContainerRef, viewableItemsRef, onSelectDate, dateStatus);

  useKeyboardNavigation(scrollContainerRef, scrollLeftButtonRef, scrollRightButtonRef);

  const instanceValue: CalendarBarInstanceOld = useMemo(() => ({ scrollToDate }), [scrollToDate]);

  useImperativeHandle(ref, () => instanceValue);

  const onViewableItemsChanged = useEvent<Exclude<FlatList['props']['onViewableItemsChanged'], null | undefined>>(
    info => {
      if (info) {
        if (!preventInitialScrollRef.current) {
          // Only run once on init. Otherwise, hitting the "back" scroll button
          // will trigger this, because the date in the previous window is selected
          // before it's been completely scrolled to
          preventInitialScrollRef.current = true;

          const isSelectedVisibleNow = info.viewableItems.some(v => selectedDate.isSame(v.item, 'day'));

          // On Firefox, there seems to be some weird scroll restoration happening. In the normal
          // operation of the app, the selected date should never be out of view of the viewport,
          // so if there is a scroll that brings the selected date out of view, scroll it back into
          // view
          if (!isSelectedVisibleNow) {
            scrollContainerRef.current?.scrollToIndex({
              animated: false,
              index: selectedDate.diff(renderedDateStart, 'days'),
            });

            return;
          }
        }

        viewableItemsRef.current = info.viewableItems || [];
        setCanScrollLeft(() => (info.viewableItems[0]?.index || 0) > 0);

        const first: Dayjs = info.viewableItems[0]?.item;
        const last: Dayjs = info.viewableItems?.at(-1)?.item;

        onVisibleDateChange(first, last);

        if (last && renderedDateEnd.diff(last, 'days') <= info.viewableItems.length) {
          // Add more days that the user can scroll to. However, do it after a delay
          // so the user can't quickly scroll many months in the future. For that they
          // should use the month calendar view
          setTimeout(() => {
            setRenderedDateEnd(last.add(info.viewableItems.length, 'days'));
          }, 1000);
        }
      }
    }
  );

  const renderedDateList = useMemo(() => {
    const dates: Dayjs[] = [];
    const count = renderedDateEnd.diff(renderedDateStart, 'days');

    for (let i = 0; i < count; i += 1) {
      dates.push(renderedDateStart.add(i, 'day'));
    }

    return dates;
  }, [renderedDateEnd, renderedDateStart]);

  const [initialVisibleIndex] = useState(() => selectedDate.diff(renderedDateStart, 'days'));

  return (
    <View style={[styles.container, SENTRY_UNMASK]} testID="CalendarBar">
      <BaseButton
        role="button"
        onPress={canScrollLeft ? scrollLeft : undefined}
        isDisabled={!canScrollLeft}
        style={[styles.scrollButton, !canScrollLeft && styles.disabled]}
        aria-hidden={!canScrollLeft}
        aria-label="Previous dates"
        testID="CalendarBar_previous"
        ref={scrollLeftButtonRef}
      >
        <ChevronRight style={styles.leftArrowIcon} />
      </BaseButton>
      <PulseProvider duration={1000}>
        <FlatList
          ref={scrollContainerRef}
          data={renderedDateList}
          horizontal
          onLayout={event => {
            // viewableItemsRef is only set when a scroll happens. So before the first scroll,
            // it's empty. This function pre-populates viewableItemsRef with the visible items
            // on the first layout of the page.
            const itemsVisible = Math.ceil(event.nativeEvent.layout.width / getItemLayout(null, 0).length);

            // Only update if viewableItemsRef hasn't been previously set, or if the number
            // of items visible doesn't match the number in viewableItemsRef.
            // The first case covers the first render - onViewableItemsChanged only runs
            // on scroll, not on inital render.
            // The second case (when the number of items doesn't match) covers when the
            // viewport is resized. When the viewport resizes, onViewableItemsChanged doesn't
            // run. This updates the list items so the very next left/right scroll button
            // press moves the viewable items the correct amount.
            if (viewableItemsRef.current.length !== itemsVisible) {
              const offset = viewableItemsRef.current?.[0]?.index ?? 0;

              viewableItemsRef.current = Array.from({ length: itemsVisible }, (_, index) => ({
                index: index + offset,
                item: renderedDateList[index],
              }));
            }
          }}
          scrollEnabled={false}
          onViewableItemsChanged={onViewableItemsChanged}
          initialScrollIndex={initialVisibleIndex}
          onEndReachedThreshold={0.3}
          showsHorizontalScrollIndicator={false}
          getItemLayout={getItemLayout}
          renderItem={p => (
            <Day
              day={p.item}
              isEnabled={!!dateStatus(p.item)}
              isLoading={isAllLoading || typeof dateStatus(p.item) !== 'boolean'}
              isSelected={p.item.isSame(selectedDate, 'day')}
              onPress={() => onSelectDate(p.item)}
            />
          )}
          ListFooterComponent={LoadingDay}
        />
      </PulseProvider>
      <BaseButton
        role="button"
        onPress={scrollRight}
        style={styles.scrollButton}
        aria-label="Next dates"
        testID="CalendarBar_next"
        ref={scrollRightButtonRef}
      >
        <ChevronRight />
      </BaseButton>
    </View>
  );
});
