import { ALL_SET_PAGE_NAME, SYMPTOMS_SURVEY_PAGE_NAME } from '~types';

import type { RoutingConfig, StackParamList } from '~types';

export const symptomsSurveyRoutes = {
  id: 'symptomsSurvey' as const,
  initial: () => ({ name: SYMPTOMS_SURVEY_PAGE_NAME }),
  routes: {
    [SYMPTOMS_SURVEY_PAGE_NAME]: {
      on: {
        submit: () => ({ name: ALL_SET_PAGE_NAME }),
      },
    },
    [ALL_SET_PAGE_NAME]: {
      reset: true,
      on: null,
    },
  },
} satisfies RoutingConfig<StackParamList>;
