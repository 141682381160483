import React from 'react';

import { withRouteBehavior } from '~modules/navigation';
// Unknown paths, just redirect to the beginning of
// the flow.
const WithRouteBehavior = withRouteBehavior();

export default function CatchAll() {
  // eslint-disable-next-line no-console
  console.warn('Page not found - redirecting to Visit Reasons');

  return <WithRouteBehavior />;
}
