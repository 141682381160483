import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { productsApi } from '~modules/api';
import { useStripeProduct } from '~modules/product';
import { appointmentParamsAtom, creditCardAtom, patientProgressAtom, userAtom } from '~modules/state';

import type { PromotionCodeType } from '@almond/api-types';

export const useCheckPromotionCode = (
  key: 'promotionCode' | 'friendReferralCode',
  promotionCodeType: PromotionCodeType
) => {
  const [creditCardState, setCreditCardState] = useRecoilState(creditCardAtom);
  const patientProgressState = useRecoilValue(patientProgressAtom);
  const { isAdmin } = useRecoilValue(userAtom);
  const { product, isLoadingProduct } = useStripeProduct();

  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const { isAuthenticated, isLoading: isAuthenticating } = useAuth0();

  useEffect(() => {
    if (isAuthenticating || !isNewMemberRemote || isLoadingProduct) {
      return;
    }

    if (key === 'promotionCode') {
      if (isAuthenticated && !isAdmin) {
        // Waiting for the patient to be loaded if the current patient is an established one.
        if (patientProgressState.subscriptionActive == null) {
          return;
        }

        // Skipping checking the promotion code if the current patient already has a subscription.
        if (patientProgressState.subscriptionActive) {
          return;
        }
      }
    }

    const promotionCode = creditCardState[key];

    if (promotionCode?.state !== 'init') {
      return;
    }

    const toCall = async () => {
      try {
        const result = await productsApi.checkPromotionCode(promotionCode.code, product?.almondProductKey);

        setCreditCardState(prevState => ({ ...prevState, [key]: result }));
      } catch (e) {
        // Swallow the error - the initial page doesn't need to worry about displaying any errors.
        // If the request fails, we'll arrive at the CreditCard page without a valid referral code lookup,
        // but then the CreditCard page will perform a lookup and show any potential error codes there.
      }
    };

    toCall();
  }, [
    creditCardState,
    isAdmin,
    isAuthenticated,
    isAuthenticating,
    isLoadingProduct,
    isNewMemberRemote,
    key,
    patientProgressState.subscriptionActive,
    product?.almondProductKey,
    promotionCodeType,
    setCreditCardState,
  ]);
};
