import React from 'react';
import { View } from 'react-native';

import { BaseButton, sizes, SkeletonLoader, Text, useTheme } from '@almond/ui';

import { dayStyles } from './styles';

import type { Dayjs } from 'dayjs';

type DayProps = {
  isEnabled?: boolean;
  isSelected?: boolean;
  onPress?: () => void;
} & ({ day: Dayjs; isLoading: false } | { day?: Dayjs; isLoading: true });

export const LoadingDay = React.memo((props: Pick<DayProps, 'day'>) => {
  const { day } = props;
  const [styles] = useTheme(dayStyles);

  return (
    <View
      style={[styles.container, styles.loading]}
      aria-label={day && `Loading ${day.format('dddd D')}`}
      testID="CalendarBar_Day-loading"
    >
      <SkeletonLoader.RoundedRectangle width="100%" height="100%" roundness={sizes.SPACING_XXS} />
    </View>
  );
});

export const Day = React.memo((props: DayProps) => {
  const { day, onPress, isSelected, isEnabled, isLoading } = props;
  const [styles] = useTheme(dayStyles);

  if (isLoading) {
    return <LoadingDay day={day} />;
  }

  return (
    <BaseButton
      role="button"
      onPress={onPress}
      style={[styles.container, isSelected && styles.selected]}
      isDisabled={!isEnabled}
      aria-label={day.format('dddd D')}
      aria-current={isSelected}
      testID="CalendarBar_Day"
      tabIndex={-1}
      // @ts-ignore
      dataSet={{ date: day.format('YYYY-MM-DD') }}
    >
      <Text
        style={[styles.text, isSelected && styles.selected, !isEnabled && styles.disabled]}
        fontStyle="book"
        size="m"
      >
        {day.format('dd MMM D')}
      </Text>
    </BaseButton>
  );
});
