import React, { useEffect, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { Container } from '~modules/layout';
import { useAsync } from '~modules/promises';
import { useSaveQuestionnaire } from '~modules/questionnaire';
import { useRouteNavigation } from '~modules/routing';
import { patientAtom, questionnaireAtom } from '~modules/state';

import { useCreatePatient } from '../../hooks';
import ActivityIndicator from './ActivityIndicator';

import type { CREATE_PATIENT_PAGE_NAME } from '~types';

export const CreatePatientPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof CREATE_PATIENT_PAGE_NAME>();
  const { isAuthenticated } = useAuth0();
  const { doAsync } = useAsync();
  const createPatient = useCreatePatient();
  const saveQuestionnaire = useSaveQuestionnaire();
  const questionnaireState = useRecoilValue(questionnaireAtom);
  const { patientUuid } = useRecoilValue(patientAtom);
  const { setParams } = useRouter();
  const [isQuestionnaireSaved, setIsQuestionnaireSaved] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) return;

    const toCall = async () => {
      await createPatient();
    };

    doAsync(toCall);
  }, [doAsync, createPatient, isAuthenticated, setParams]);

  useEffect(() => {
    if (!patientUuid || isQuestionnaireSaved) return;

    const toCall = async () => {
      await saveQuestionnaire(questionnaireState, 'visit_questions', 'visitQuestions');
      await dispatch('complete');
    };

    doAsync(toCall);
    setIsQuestionnaireSaved(true);
  }, [dispatch, doAsync, isQuestionnaireSaved, patientUuid, questionnaireState, saveQuestionnaire]);

  return (
    <Container id="createPatient" title={t('createPatient.title')} size="XXL">
      <ActivityIndicator />
    </Container>
  );
};
