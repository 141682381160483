import { useRecoilValue } from 'recoil';

import { checkIfPostBookingFlow, useRouteNavigation } from '~modules/routing';
import { integrationAtom, userAtom } from '~modules/state';

export const useShouldDisplayIdleModal = () => {
  const { isAdmin } = useRecoilValue(userAtom);
  const integrationState = useRecoilValue(integrationAtom);
  const { routingConfigId } = useRouteNavigation();
  const isPostBookingFlow = checkIfPostBookingFlow(routingConfigId);

  return (
    !isAdmin && !isPostBookingFlow && !integrationState.isIdleModalDisabled && !integrationState.isAbandonModalVisible
  );
};
