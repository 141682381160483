import React, { useMemo } from 'react';
import { View } from 'react-native';

import { LOCAL_DATE_SHORT_FORMAT } from '@almond/date-and-time';
import { useTranslation } from '@almond/localization';
import { Button, LabOrderIcon, SkeletonLoader, Text, useTheme } from '@almond/ui';
import dayjs from 'dayjs';

import themedStyles from './styles';

import type { PatientLabOrdersOut } from '@almond/api-types';
import type { StyleProp, ViewStyle } from 'react-native';

export type ItemProps = {
  item: PatientLabOrdersOut['labOrders'][number];
  style?: StyleProp<ViewStyle>;
};

export const Item: React.FC<ItemProps> = props => {
  const { item, style } = props;
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);

  const orderingDate = useMemo(() => dayjs(item.chartDate).format(LOCAL_DATE_SHORT_FORMAT), [item.chartDate]);

  return (
    <View style={[styles.item, style]} testID="LabOrdersItem">
      <View style={styles.itemIcon}>
        <LabOrderIcon />
      </View>
      <View style={styles.itemCenter}>
        <Text style={styles.itemTitle} fontStyle="bold">
          {t('labOrders.itemTitle', { orderingDate })}
        </Text>
        <Text style={styles.itemSubtitle}>
          {t(`labOrders.orderedBy`, { provider: item.orderingCareTeamMember.profileLabel })}
        </Text>
      </View>
      <Button
        // TODO This should render as an <a href>, not a <button onClick>
        onPress={() => window.open(item.printableUrl, '_blank')}
        type="primary"
        isSquared
        fixedWidth={false}
        size="m"
        fontStyle="bold"
      >
        {t('labOrders.view')}
      </Button>
    </View>
  );
};

export const LoadingItem = () => {
  const [styles] = useTheme(themedStyles);

  return <SkeletonLoader.Rectangle style={styles.item} />;
};
