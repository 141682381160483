import React, { Fragment } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { getVisitReasonIcon, SENTRY_UNMASK_TEXT, SkeletonLoader, Text, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import groupBy from 'object.groupby';

import { BlockItemLink } from '../../components';

import themedStyles from './styles';

import type { VisitReasonOut } from '@almond/api-types';

type Category = 'general' | 'Group Pregnancy Care' | 'PCOS' | 'Health Coaching' | 'Baseline' | 'Menopause';

type VisitReasonsProps = {
  isLoading: boolean;
  visitReasons: VisitReasonOut[] | undefined;
  onSubmit: (item: string) => Promise<void>;
};

export const VisitReasons = (props: VisitReasonsProps) => {
  const { isLoading, visitReasons, onSubmit } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const searchParams = useLocalSearchParams();

  if (isLoading || !visitReasons) {
    return (
      <View style={styles.loaderContainer}>
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
        <SkeletonLoader.RoundedRectangle width="100%" height={80} roundness={8} />
      </View>
    );
  }

  // Unique categories, in order of first appearance
  let orderedCategories: string[];
  let groupedVisitReasons: Record<string, VisitReasonOut[]>;

  if (searchParams.category) {
    const filteredVisitReasons = visitReasons.filter(vr => vr.category === searchParams.category);
    // Subcategories aren't specified for all the visit reasons on the backend, so we need to use a default value.
    const defaultSubcategory = t(`visitReasons.subcategories.${searchParams.category as Category}`);

    orderedCategories = [...new Set(filteredVisitReasons.map(v => v.subcategory ?? defaultSubcategory))];
    groupedVisitReasons = groupBy(filteredVisitReasons, vr => vr.subcategory ?? defaultSubcategory);
  } else {
    const categories = t('visitReasons.categories', { returnObjects: true });
    // Category used to be a key ("general", "maternity"). We're shifting to "human-readable"
    // categories. Once "general" and "maternity" switch to human-readable in production, we can
    // remove the handling of those values (and their translation keys).
    const categoryKey = (vr: VisitReasonOut) => vr.category as keyof typeof categories;

    orderedCategories = [...new Set(visitReasons.map(vr => categories[categoryKey(vr)]))];
    groupedVisitReasons = groupBy(visitReasons, vr => categories[categoryKey(vr)]);
  }

  return (
    <View style={styles.topAlign}>
      {orderedCategories.map(category => {
        return (
          <Fragment key={category}>
            <Text fontStyle="bold" size="m" style={[styles.sectionTitle, SENTRY_UNMASK_TEXT]}>
              {category}
            </Text>
            {groupedVisitReasons[category].map(item => (
              <BlockItemLink
                key={item.uuid}
                testID={item.code}
                title={item.title}
                subtitle={item.description}
                icon={getVisitReasonIcon(item.code)}
                onPress={() => onSubmit(item.code)}
              />
            ))}
          </Fragment>
        );
      })}
    </View>
  );
};
