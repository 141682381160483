import { checkoutRoutes } from './checkout';
import { curatedFlowRoutes } from './curatedFlow';
import { historyRoutes } from './history';
import { initialRoutes } from './initial';
import { initialNewMemberRoutes } from './initialNewMember';
import { labRoutes } from './lab';
import { postBookingRoutes } from './postBooking';
import { signupRoutes } from './signup';
import { symptomsSurveyRoutes } from './symptomsSurvey';
import { testingRoutes } from './testing';

const allRoutes = {
  [signupRoutes.id]: signupRoutes,
  [curatedFlowRoutes.id]: curatedFlowRoutes,
  [initialRoutes.id]: initialRoutes,
  [initialNewMemberRoutes.id]: initialNewMemberRoutes,
  [labRoutes.id]: labRoutes,
  [testingRoutes.id]: testingRoutes,
  [historyRoutes.id]: historyRoutes,
  [checkoutRoutes.id]: checkoutRoutes,
  [postBookingRoutes.id]: postBookingRoutes,
  [symptomsSurveyRoutes.id]: symptomsSurveyRoutes,
};

export default allRoutes;
