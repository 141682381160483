import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

// Combining postBookingAdmin and postBookingSelf. Combining this into /login
// and PatientLoginPage.
export default () => {
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  return <Redirect href={`/login?${searchParams}`} />;
};
