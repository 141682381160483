import { patientProgressAtom } from '~modules/state';
import {
  ADDRESS_PAGE_NAME,
  ALL_SET_PAGE_NAME,
  ALLERGIES_PAGE_NAME,
  DOCTOR_INFO_PAGE_NAME,
  DRUG_ALLERGIES_PAGE_NAME,
  FAMILY_HISTORY_PAGE_NAME,
  HEALTH_CONDITIONS_PAGE_NAME,
  HISTORY_INTERSTITIAL_PAGE_NAME,
  HOSPITALIZATION_PAGE_NAME,
  INTAKE_FORM_INTRO_PAGE_NAME,
  INTAKE_FORM_PAGE_NAME,
  MEDICATIONS_PAGE_NAME,
  NEXT_STEPS_PAGE_NAME,
  OCCUPATION_PAGE_NAME,
  PERNICIOUS_HABITS_PAGE_NAME,
  PHARMACY_PAGE_NAME,
  PREGNANCIES_PAGE_NAME,
  PRIMARY_CARE_PROVIDER_PAGE_NAME,
  REFERRAL_PAGE_NAME,
  SURGERIES_PAGE_NAME,
} from '~types';

import { patientHistoryProgress } from '../services/patientHistoryProgress';

import type { IntakeFormParams, RoutingConfig, StackParamList } from '~types';

export const historyRoutes = {
  id: 'history' as const,
  initialAsync: async getState => {
    const { historyData, pharmacy, address, referral } = await getState(patientProgressAtom);

    if (!historyData || !pharmacy || !address || !referral) {
      return { name: HISTORY_INTERSTITIAL_PAGE_NAME };
    }

    return patientHistoryProgress(getState);
  },
  routes: {
    [HISTORY_INTERSTITIAL_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const { historyData } = await getState(patientProgressAtom);

          if (!historyData) {
            return { name: HEALTH_CONDITIONS_PAGE_NAME };
          }

          return patientHistoryProgress(getState);
        },
      },
    },
    [HEALTH_CONDITIONS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: MEDICATIONS_PAGE_NAME }),
      },
    },
    [MEDICATIONS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: DRUG_ALLERGIES_PAGE_NAME }),
      },
    },
    [DRUG_ALLERGIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: ALLERGIES_PAGE_NAME }),
      },
    },
    [ALLERGIES_PAGE_NAME]: {
      on: {
        submit: (_1, _2, params?: { isObAppointment: boolean }) => {
          if (params?.isObAppointment) return { name: FAMILY_HISTORY_PAGE_NAME };

          return { name: SURGERIES_PAGE_NAME };
        },
      },
    },
    [SURGERIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PREGNANCIES_PAGE_NAME }),
      },
    },
    [PREGNANCIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: HOSPITALIZATION_PAGE_NAME }),
      },
    },
    [HOSPITALIZATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: FAMILY_HISTORY_PAGE_NAME }),
      },
    },
    [FAMILY_HISTORY_PAGE_NAME]: {
      on: {
        submit: () => ({ name: DOCTOR_INFO_PAGE_NAME }),
      },
    },
    [DOCTOR_INFO_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PRIMARY_CARE_PROVIDER_PAGE_NAME }),
      },
    },
    [PRIMARY_CARE_PROVIDER_PAGE_NAME]: {
      on: {
        submit: () => ({ name: OCCUPATION_PAGE_NAME }),
      },
    },
    [OCCUPATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: PERNICIOUS_HABITS_PAGE_NAME }),
      },
    },
    [PERNICIOUS_HABITS_PAGE_NAME]: {
      on: {
        submit: getState => patientHistoryProgress(getState),
      },
    },
    // End of "history" routes, beginning of "accountSetup" routes
    [PHARMACY_PAGE_NAME]: {
      on: {
        submit: getState => patientHistoryProgress(getState),
      },
    },
    [ADDRESS_PAGE_NAME]: {
      on: {
        submit: getState => patientHistoryProgress(getState),
      },
    },
    [REFERRAL_PAGE_NAME]: {
      on: {
        submit: getState => patientHistoryProgress(getState),
      },
    },
    [INTAKE_FORM_INTRO_PAGE_NAME]: {
      on: {
        submit: (_, searchParams) => ({
          name: INTAKE_FORM_PAGE_NAME,
          params: { formType: searchParams.formType as IntakeFormParams['formType'] },
        }),
      },
    },
    [INTAKE_FORM_PAGE_NAME]: {
      on: {
        submit: async getState => patientHistoryProgress(getState),
      },
    },
    [NEXT_STEPS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: ALL_SET_PAGE_NAME }),
      },
    },
    [ALL_SET_PAGE_NAME]: {
      reset: true,
      on: null,
    },
  },
} satisfies RoutingConfig<StackParamList>;
