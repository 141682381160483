import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { PropsWithChildren } from 'react';

export type CenterContainerProps = PropsWithChildren<{
  includesTitle?: boolean;
}>;

export const CenterContainer = (props: CenterContainerProps) => {
  const { children, includesTitle } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.verticalCenter, includesTitle && styles.verticalCenterWithTitle]}>
      <View style={styles.content}>{children}</View>
    </View>
  );
};
