import type { FormTypesEnum } from '@almond/api-types';

export const INTAKE_FORM_INTRO_PAGE_NAME = `/forms/[formType]/intro` as const;
export const INTAKE_FORM_PAGE_NAME = `/forms/[formType]` as const;
export const SYMPTOMS_SURVEY_PAGE_NAME = `/symptoms-survey` as const;

export type IntakeFormIntroParams = { formType: FormTypesEnum };
export type IntakeFormParams = { formType: FormTypesEnum };

export type TypeformParams = {
  [INTAKE_FORM_INTRO_PAGE_NAME]: IntakeFormIntroParams;
  [INTAKE_FORM_PAGE_NAME]: IntakeFormParams;
  [SYMPTOMS_SURVEY_PAGE_NAME]: undefined;
};
