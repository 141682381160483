import type { AccountSetupParams } from './accountSetup';
import type { AppointmentParams } from './appointment';
import type { CuratedParams } from './curated';
import type { HistoryParams } from './history';
import type { PatientParams } from './patient';
import type { ProfileParams } from './profile';
import type { TypeformParams } from './typeform';

export type StackParamList = AccountSetupParams &
  CuratedParams &
  AppointmentParams &
  HistoryParams &
  PatientParams &
  ProfileParams &
  TypeformParams;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface RootParamList extends StackParamList {}
  }
}

export * from './accountSetup';
export * from './appointment';
export * from './curated';
export * from './history';
export * from './navigation';
export * from './navigationWithoutRouting';
export * from './patient';
export * from './profile';
export * from './typeform';
