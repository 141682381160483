import React from 'react';

import { useTranslation } from '@almond/localization';
import { useToastNotification } from '@almond/ui';

import { useCalculatePrice } from '~modules/payment';
import { useStripeProduct } from '~modules/product';

import { NotSureAboutCostModal, type NotSureAboutCostModalProps } from '../NotSureAboutCostModal';

export type FirstMonthFreeModalProps = Omit<
  NotSureAboutCostModalProps,
  'title' | 'priceOutput' | 'onSubmit' | 'isLoading' | 'joinAlmondButtonTestID'
>;

export const FirstMonthFreeModal = (props: FirstMonthFreeModalProps) => {
  const { onRequestClose, ...restProps } = props;
  const { t } = useTranslation();
  const { product } = useStripeProduct();
  const priceOutput = useCalculatePrice(props.isVisible ? product : undefined);
  const { showToast } = useToastNotification();

  const handleSubmit = () => {
    showToast({ text: t('firstMonthFreeModal.toastText'), type: 'info', duration: 3200 });
    onRequestClose?.();
  };

  return (
    <NotSureAboutCostModal
      title={t('firstMonthFreeModal.title')}
      joinAlmondButtonTestID="FirstMonthFreeModal-Submit"
      priceOutput={priceOutput}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      {...restProps}
    />
  );
};
