import React from 'react';

import { useTranslation } from '@almond/localization';
import { useTheme } from '@almond/ui';

import { Container } from '~modules/layout';
import { useIsBaselineProduct } from '~modules/product';
import { MessageWithImage } from '~modules/ui';

import themedStyles from './styles';

export const ConfirmEmailPage: React.FC = () => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const isBaseline = useIsBaselineProduct();
  const subtitle = t('confirmEmail.message.subtitle', {
    buttonTitle: isBaseline
      ? t('confirmEmail.message.baselineEmailButton')
      : t('confirmEmail.message.gynecologyEmailButton'),
  });

  return (
    <Container id="confirmEmail" contentContainerStyle={styles.contentContainer} shouldHideCloseButton>
      <MessageWithImage
        title={t('confirmEmail.message.title')}
        subtitle={subtitle}
        imageProps={{
          source: 'womanLookingAhead',
          desktopDimensions: { width: 570, height: 500 },
          mobileDimensions: { width: 250, height: 220 },
        }}
        breakpoint={680}
      />
    </Container>
  );
};
