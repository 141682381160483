import React, { useState } from 'react';
import { Pressable, View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { BaseModal, Button, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';

import { profileAtom, userAtom } from '~modules/state';

import { useArtemisRedirectUrl, useRedirectBackToArtemis } from '../../hooks';
import CloseButton from '../CloseButton';

import themedStyles from './styles';

type UserAvatarMenuProps = {
  closeButtonVisible?: boolean;
};

// eslint-disable-next-line max-statements
export const UserAvatarMenu = (props: UserAvatarMenuProps) => {
  const [styles] = useTheme(themedStyles);
  const userState = useRecoilValue(userAtom);
  const [isVisible, setIsVisible] = useState(false);
  const { user, logout } = useAuth0();
  const profileState = useRecoilValue(profileAtom);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const { backToArtemisLinkProps } = useRedirectBackToArtemis();
  const artemisRedirectUrl = useArtemisRedirectUrl();
  const { t } = useTranslation();

  let initials = '';
  let name = '';

  const firstName = profileState.preferredFirstName || profileState.firstName;

  if (!userState.isAdmin && firstName && profileState.lastName) {
    initials = firstName[0] + profileState.lastName[0];
    name = [
      profileState.firstName,
      profileState.preferredFirstName ? `"${profileState.preferredFirstName}"` : undefined,
      profileState.lastName,
    ]
      .filter(n => n)
      .join(' ');
  } else if (user && userState.isAdmin) {
    const email = user.email ?? 'Almond User';

    initials = email[0].toUpperCase();
    name = email;
  }

  const closeButton = (
    <CloseButton
      isVisible={props.closeButtonVisible || (!!initials && isMobile)}
      showAsIconButton={!initials && isMobile}
    />
  );

  if (!initials) {
    return closeButton;
  }

  return (
    <>
      {isDesktop && closeButton}
      <Pressable role="button" onPress={() => setIsVisible(true)} style={styles.userAvatarMenu}>
        <Text fontStyle="bold" style={styles.initials}>
          {initials}
        </Text>
      </Pressable>
      <BaseModal isVisible={isVisible} onRequestClose={() => setIsVisible(false)} backdropStyle={styles.modalBackdrop}>
        <View style={styles.menu}>
          <Text size="m" fontStyle="bold" style={styles.menuText} testID="UserMenu-Username">
            {name}
          </Text>
          {isMobile && closeButton}
          <Button {...backToArtemisLinkProps('/settings')} size="s" fixedWidth={false}>
            {t('settings')}
          </Button>
          <Button
            size="s"
            fixedWidth={false}
            onPress={() => logout({ logoutParams: { returnTo: artemisRedirectUrl } })}
            type="accent"
          >
            {t('signOut')}
          </Button>
        </View>
      </BaseModal>
    </>
  );
};
