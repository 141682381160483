import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

// `/complete_on_boarding` link is in emails sent to members, so even if
// we change the email content away from this link, we'll need to
// keep this redirect until the farthest-in-the-future appt confirmed
// with an email with a `/complete_on_boarding` link has been completed.
export default () => {
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  return <Redirect href={`/login?${searchParams}`} />;
};
