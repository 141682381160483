import React from 'react';

import { useTranslation } from '@almond/localization';
import { Button, useTheme } from '@almond/ui';

import { Container } from '~modules/layout';
import { useIsBaselineProduct } from '~modules/product';
import { useRouteNavigation } from '~modules/routing';
import { ConfettiBurstsDecoration, MessageWithImage } from '~modules/ui';

import themedStyles from './styles';

import type { FINISH_ACCOUNT_SETUP_PAGE_NAME } from '~types';

export const FinishAccountSetupPage: React.FC = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof FINISH_ACCOUNT_SETUP_PAGE_NAME>();
  const [styles] = useTheme(themedStyles);
  const isBaseline = useIsBaselineProduct();

  return (
    <Container
      id="finishAccountSetup"
      contentContainerStyle={styles.contentContainer}
      backgroundDecoration={<ConfettiBurstsDecoration />}
      shouldHideCloseButton
    >
      <MessageWithImage
        title={t(`finishAccountSetup.${isBaseline ? 'baselineTitle' : 'title'}`)}
        subtitle={t(`finishAccountSetup.${isBaseline ? 'baselineSubtitle' : 'subtitle'}`)}
        footer={
          <Button onPress={() => dispatch('submit')} testID="Submit" size="m">
            {t('continue')}
          </Button>
        }
        imageProps={{
          source: 'relaxingWoman',
          desktopDimensions: { width: 634, height: 381 },
          mobileDimensions: { width: 300, height: 180 },
        }}
        breakpoint={680}
      />
    </Container>
  );
};
