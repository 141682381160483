import { isBaselineProduct } from '~modules/product';
import { patientAtom, patientProgressAtom, userAtom } from '~modules/state';
import {
  ALL_SET_PAGE_NAME,
  BOOK_APPOINTMENT_PAGE_NAME,
  CONFIRM_EMAIL_PAGE_NAME,
  CONFIRM_PURCHASE_PAGE_NAME,
  CONSENT_SIGNATURE_PAGE_NAME,
  CREDIT_CARD_PAGE_NAME,
  FINISH_ACCOUNT_SETUP_PAGE_NAME,
  INSURANCE_ID_PAGE_NAME,
  NEXT_STEPS_PAGE_NAME,
  PRICING_EXPLANATION_PAGE_NAME,
} from '~types';

import type { InsuranceDetail } from '@almond/api-types';
import type { BaseParamsWithoutRouting, RoutingConfig, StackParamList } from '~types';
import type { RecoilState } from 'recoil';

const getBookingDestination = async (
  getState: <T>(recoilValue: RecoilState<T>) => Promise<T>,
  searchParams: BaseParamsWithoutRouting
) => {
  const { subscriptionActive } = await getState(patientProgressAtom);
  const { isNewMember } = await getState(patientAtom);

  if (!subscriptionActive && !isNewMember) {
    return { name: PRICING_EXPLANATION_PAGE_NAME };
  }

  if (isBaselineProduct(searchParams.product)) {
    return { name: CONFIRM_PURCHASE_PAGE_NAME };
  }

  // If isNewMember, coming from "Create Patient" page (so replace).
  // Otherwise, coming from Scheduling page, don't replace.
  return { name: BOOK_APPOINTMENT_PAGE_NAME, replace: isNewMember };
};

export const checkoutRoutes = {
  id: 'checkout' as const,
  initialAsync: async (getState, searchParams) => {
    const patientProgressState = await getState(patientProgressAtom);
    const userState = await getState(userAtom);
    const { isAdmin } = userState;

    if (!isAdmin && !patientProgressState.consent) {
      return { name: CONSENT_SIGNATURE_PAGE_NAME };
    }

    return getBookingDestination(getState, searchParams);
  },
  routes: {
    [CONSENT_SIGNATURE_PAGE_NAME]: {
      reset: true,
      on: {
        submit: getBookingDestination,
      },
    },
    [BOOK_APPOINTMENT_PAGE_NAME]: {
      on: {
        submit: async (getState, _, insurance: Pick<InsuranceDetail, 'hasCardFrontImage' | 'isAccepted'>) => {
          const patientProgressState = await getState(patientProgressAtom);
          const userState = await getState(userAtom);
          const { isAdmin } = userState;
          const shouldOpenInsuranceId = !insurance.hasCardFrontImage && insurance.isAccepted;

          if (shouldOpenInsuranceId) {
            if (isAdmin) {
              return {
                name: INSURANCE_ID_PAGE_NAME,
                params: { can_skip: 'true', should_hide_close_button: 'true' },
              };
            }

            return { name: FINISH_ACCOUNT_SETUP_PAGE_NAME };
          }

          if (isAdmin) {
            return { name: ALL_SET_PAGE_NAME };
          }

          if (!patientProgressState.emailVerified) {
            return { name: CONFIRM_EMAIL_PAGE_NAME };
          }

          return { name: NEXT_STEPS_PAGE_NAME };
        },
        // If a credit card payment on subscription creation fails,
        // we need to redirect back to the credit card page. We'll drop the
        // user into the resubscribe flow because we need:
        // 1. The user to re-enter their credit card details and get taken
        // back to the BOOK_APPOINTMENT page immediately after
        // 2. To create the subscription on the BOOK_APPOINTMENT page (not the CC page)
        cardRequired: (_1, _2, error) => ({
          name: CREDIT_CARD_PAGE_NAME,
          params: { error },
        }),
      },
    },
    [CONFIRM_PURCHASE_PAGE_NAME]: {
      on: {
        submit: async (getState, _, insurance: Pick<InsuranceDetail, 'hasCardFrontImage' | 'isAccepted'>) => {
          const patientProgressState = await getState(patientProgressAtom);
          const userState = await getState(userAtom);
          const { isAdmin } = userState;
          const shouldOpenInsuranceId = !insurance.hasCardFrontImage && insurance.isAccepted;

          if (shouldOpenInsuranceId) {
            if (isAdmin) {
              return {
                name: INSURANCE_ID_PAGE_NAME,
                params: { can_skip: 'true', should_hide_close_button: 'true' },
              };
            }

            return { name: FINISH_ACCOUNT_SETUP_PAGE_NAME };
          }

          if (isAdmin) {
            return { name: ALL_SET_PAGE_NAME };
          }

          if (!patientProgressState.emailVerified) {
            return { name: CONFIRM_EMAIL_PAGE_NAME };
          }

          return { name: NEXT_STEPS_PAGE_NAME };
        },

        cardRequired: (_1, _2, error) => ({
          name: CREDIT_CARD_PAGE_NAME,
          params: { error },
        }),
      },
    },
    [PRICING_EXPLANATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: CREDIT_CARD_PAGE_NAME }),
        learnMore: () => {
          throw new Error('LearnMore action only available for new member bookings');
        },
      },
    },
    [CREDIT_CARD_PAGE_NAME]: {
      on: {
        submit: (_, searchParams) => {
          if (isBaselineProduct(searchParams.product)) {
            return { name: CONFIRM_PURCHASE_PAGE_NAME };
          }

          return { name: BOOK_APPOINTMENT_PAGE_NAME };
        },
      },
    },
    [ALL_SET_PAGE_NAME]: {
      reset: true,
      on: null,
    },
    [NEXT_STEPS_PAGE_NAME]: {
      on: {
        submit: () => ({ name: ALL_SET_PAGE_NAME }),
      },
    },
    [CONFIRM_EMAIL_PAGE_NAME]: { reset: true, on: null },
    [FINISH_ACCOUNT_SETUP_PAGE_NAME]: {
      reset: true,
      on: {
        submit: () => ({
          name: INSURANCE_ID_PAGE_NAME,
          params: { can_skip: 'true', should_hide_close_button: 'true' },
        }),
      },
    },
    [INSURANCE_ID_PAGE_NAME]: {
      on: {
        submit: async getState => {
          const { emailVerified } = await getState(patientProgressAtom);
          const userState = await getState(userAtom);
          const { isAdmin } = userState;

          if (isAdmin) {
            return { name: ALL_SET_PAGE_NAME };
          }

          if (!emailVerified) {
            return { name: CONFIRM_EMAIL_PAGE_NAME };
          }

          return { name: NEXT_STEPS_PAGE_NAME };
        },
      },
      reset: true,
    },
  },
} satisfies RoutingConfig<StackParamList>;
