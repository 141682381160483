import { useEffect } from 'react';

import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { useVisitReasons } from '~modules/appointment';
import { useCheckCode } from '~modules/payment';
import { userAtom } from '~modules/state';

export const useCheckPCOSMembership = () => {
  const { isAdmin } = useRecoilValue(userAtom);
  const searchParams = useLocalSearchParams();
  const { visitReasons } = useVisitReasons();
  const { checkCode } = useCheckCode();

  // For PCOS membership, give 100% discount. Care Team will manually charge PCOS membership fee
  useEffect(() => {
    if (isAdmin && visitReasons?.find(v => v.code === searchParams.visit_reason)?.isPcos) {
      checkCode('PCOSMEMBERSHIP');
    }
  }, [searchParams.visit_reason, checkCode, isAdmin, visitReasons]);
};
