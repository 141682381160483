import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { Label } from '@almond/ui';
import { formatPriceInCents, parseStrikethrough } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { patientsApi } from '~modules/api';
import { PAYMENT_FAILED_ERROR_STATUS_CODE, PaymentRequiredError } from '~modules/errors';
import { FormFooter, JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { useCalculatePrice, useCreateInvoice } from '~modules/payment';
import { useStripeProduct } from '~modules/product';
import { useAsync } from '~modules/promises';
import { useRouteNavigation } from '~modules/routing';
import { patientProgressAtom, userAtom } from '~modules/state';
import { MessageContainer } from '~modules/ui';

import { useCreateAccount } from '../../hooks';
import { logError } from '../../logger';

import type { CONFIRM_PURCHASE_PAGE_NAME } from '~types';

export const ConfirmPurchasePage: React.FC = () => {
  const { t } = useTranslation();
  const { doAsync: doAsyncPurchase, isLoading: isPurchasing } = useAsync();
  const userState = useRecoilValue(userAtom);
  const { isAdmin } = userState;
  const { dispatch } = useRouteNavigation<typeof CONFIRM_PURCHASE_PAGE_NAME>();
  const { product, allProducts, isLoadingProduct } = useStripeProduct();
  const { fullPrice, discountPrice, isLoadingPrice } = useCalculatePrice(product);
  const createInvoice = useCreateInvoice(discountPrice);
  const createAccount = useCreateAccount();
  const patientProgressState = useRecoilValue(patientProgressAtom);

  const priceContent = useMemo(() => {
    if (discountPrice === fullPrice) {
      return formatPriceInCents(discountPrice);
    }

    return parseStrikethrough(`~${formatPriceInCents(fullPrice)}~ ${formatPriceInCents(discountPrice)}`);
  }, [fullPrice, discountPrice]);

  const handleSubmit = useCallback(async () => {
    const toCall = async () => {
      let invoice: string | null;

      if (patientProgressState.invoice) {
        await createAccount();

        return;
      }

      try {
        invoice = await createInvoice();
      } catch (error: any) {
        if (error instanceof PaymentRequiredError && error.status === PAYMENT_FAILED_ERROR_STATUS_CODE) {
          return dispatch('cardRequired', error.message);
        }

        throw error;
      }

      if (!invoice) {
        if (isAdmin) {
          await patientsApi.createPendingProduct(allProducts, product?.almondProductKey);
        } else {
          logError(
            new Error(`Invoice wasn't purchased for patient user.`),
            JSON.stringify({ userId: userState.user?.authId })
          );

          return;
        }
      }

      await createAccount();
    };

    doAsyncPurchase(toCall);
  }, [
    doAsyncPurchase,
    patientProgressState.invoice,
    createAccount,
    createInvoice,
    dispatch,
    isAdmin,
    allProducts,
    product?.almondProductKey,
    userState.user?.authId,
  ]);

  return (
    <JotaiForm onSubmit={handleSubmit} isLoading={isPurchasing} isDataLoading={isLoadingPrice || isLoadingProduct}>
      <Container
        id="confirmPurchase"
        title={t('confirmPurchase.title')}
        isLoading={isLoadingPrice || isLoadingProduct}
        footer={
          <FormFooter
            submitButtonTitle={t('confirmPurchase.submitTitle')}
            submitButtonTestID="ConfirmPurchase"
            submitButtonType="accent"
          />
        }
      >
        {product && (
          <MessageContainer>
            <Label title={t('confirmPurchase.serviceName')}>{product?.name}</Label>
            <Label title={t('confirmPurchase.payment')} isLast>
              {priceContent}
            </Label>
          </MessageContainer>
        )}
      </Container>
    </JotaiForm>
  );
};
