import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  background: {
    backgroundColor: theme.lightSecondaryBackground,
  },
  widget: {
    flex: 1,
  },
  footer: {
    paddingVertical: sizes.SPACING_S,
    alignItems: 'center',
    maxWidth: '100%',
    gap: sizes.SPACING_M,
  },

  contactUs: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 0,
  },
}));
