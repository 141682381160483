.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  padding-inline: 8px;
  height: 64px;
  background: var(--color-light-accent);
}

.visitReasonIcon {
  margin-right: 10px;
}

.currentVisitReason {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  padding-inline: 4px;
}

/* MOBILE_WIDTH_THRESHOLD */
@media (max-width: 600px) {
  .wrapper {
    background: transparent;
  }
}
