/* eslint-disable max-statements */

import { useRef } from 'react';

import { shouldLogAnalytics, trackShareASaleTransaction, useTrackAnalyticsEvent } from '@almond/analytics';
import { useRecoilCallback } from 'recoil';

import env from '~env';
import { useStripeProduct } from '~modules/product';
import { creditCardAtom, patientAtom, profileProgressAtom, userAtom } from '~modules/state';

import { log, logAndCapture } from '../logger';
import { useConfirmPayment } from './useConfirmPayment';

import type { CreateInvoiceIn, CreateSubscriptionIn } from '@almond/api-types';

const productEventMap = {
  baseline: 'baseline_purchased',
  gynecology_membership: 'membership_purchased',
  virtual_membership: 'virtual_membership_purchased',
} as const;

export type PurchaseProps = {
  createPayment: (params: CreateSubscriptionIn | CreateInvoiceIn) => Promise<{
    stripeId: string | undefined;
    clientSecret?: string;
    type?: string;
    status?: string;
  }>;
  onSuccess?: () => void;
  discountPrice: number;
};

export const usePurchase = ({ createPayment, onSuccess, discountPrice = 0 }: PurchaseProps) => {
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const confirmPayment = useConfirmPayment();
  const { product } = useStripeProduct();

  const secretRef = useRef<Record<string, { clientSecret?: string; type?: string }>>({});

  return useRecoilCallback(
    recoilInterface => async (paymentMethod?: string) => {
      const profileProgressState = await recoilInterface.snapshot.getPromise(profileProgressAtom);
      const userState = await recoilInterface.snapshot.getPromise(userAtom);
      const creditCardState = await recoilInterface.snapshot.getPromise(creditCardAtom);
      const { patientUuid, isNewMember } = await recoilInterface.snapshot.getPromise(patientAtom);
      const { isAdmin } = userState;

      if (!patientUuid) {
        logAndCapture("Can't create payment without patient_uuid");

        return null;
      }

      if (!product) {
        logAndCapture("Can't create payment without product");

        return null;
      }

      if (!profileProgressState?.creditCard) {
        if (isAdmin) {
          log('Admin user - skipping payment creation since no credit card has been entered');

          return null;
        }
      }

      log('Creating payment for the patient');

      const isFirstMonthFreeApplied =
        creditCardState.promotionCode?.state === 'success' && !!creditCardState?.promotionCode?.isFirstMonthFree;
      const promotionCode =
        creditCardState.promotionCode?.state === 'success' && creditCardState.promotionCode.code !== 'firstmonthfree'
          ? creditCardState.promotionCode.id
          : undefined;
      const friendReferralCode =
        creditCardState.friendReferralCode?.state === 'success' ? creditCardState.friendReferralCode.id : undefined;

      const payment = await createPayment({
        promotionCode,
        friendReferralCode,
        firstMonthFree: isFirstMonthFreeApplied,
        price: product.price.id,
      });

      if (!payment.stripeId) {
        logAndCapture('Payment ID was not created despite a successful payment request to the API');

        return null;
      }

      const value = secretRef.current[payment.stripeId] || {};

      secretRef.current[payment.stripeId] = value;
      value.clientSecret = payment.clientSecret ?? value.clientSecret;
      value.type = payment.type ?? value.type;

      if (payment.status !== 'paid') {
        await confirmPayment(value.type || 'payment', value.clientSecret, paymentMethod);
      }

      if (shouldLogAnalytics()) {
        trackShareASaleTransaction(payment.stripeId, discountPrice, env.MERCHANT_ID);
      }

      trackAnalyticsEvent(productEventMap[product.almondProductKey], {
        isNewMember,
        patientUuid,
        price: discountPrice,
        content_id: product.id,
        content_name: product.name,
        content_type: 'product',
      });

      onSuccess?.();

      return payment.stripeId;
    },
    [product, createPayment, trackAnalyticsEvent, discountPrice, onSuccess, confirmPayment]
  );
};
