import { withRouteBehavior } from '~modules/navigation';
import { configurations } from '~modules/routing';
import { IntakeFormPage } from '~modules/typeform';

export default withRouteBehavior(IntakeFormPage, {
  title: 'Symptoms Survey',
  allowDirectRouting: true,
  routingConfig: configurations.symptomsSurvey,
  disableIdleModal: true,
  requireAuth: true,
});
