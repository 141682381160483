/* eslint-disable max-lines */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import { dateAndTimeParsers } from '@almond/date-and-time';
import { ScrollView, useBrowserTypeMap, useTheme } from '@almond/ui';
import dayjs from 'dayjs';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';
import useSWRImmutable from 'swr/immutable';

import { DEFAULT_PROVIDER_TYPES, PHYSICIANS_PATH, physiciansApi } from '~modules/api';
import { isVirtualMembershipProduct, useStripeMembership } from '~modules/product';
import { appointmentParamsAtom, patientAtom } from '~modules/state';
import { CalendarBarOld } from '~modules/ui';

import { MAX_CALENDAR_BAR_DAYS_VISIBLE } from '../../config';
import { logError } from '../../logger';
import { getNextVisitInValue } from '../../services';
import { AvailabilitySection } from '../AvailabilitySection';
import { DisclaimerModal } from '../DisclaimerModal';
import { CalendarButton } from './CalendarButton';
import { TODAY } from './config';
import { isAppointmentPrimary } from './isAppointmentPrimary';
import { SchedulingErrorPage } from './SchedulingErrorPage';
import { useMonthlyRequest } from './useMonthlyRequest';
import { useScrollToSelectedAppointmentType } from './useScrollToSelectedAppointmentType';
import { useSelectTime } from './useSelectTime';
import { useSetInitialDate } from './useSetInitialDate';
import { useSingleDayData } from './useSingleDayData';
import { WaitlistBanner } from './WaitlistBanner';
import { WaitlistFooter } from './WaitlistFooter';

import { schedulingThemedStyles } from './styles';

import type { VisitReason } from '../../hooks';
import type { CalendarBarInstanceOld } from '~modules/ui';
import type { SchedulingParams } from '~types';
import type { Dayjs } from 'dayjs';
import type { ScrollView as ScrollViewType } from 'react-native';

const getPhysicians = () => physiciansApi.listPhysicians(true, DEFAULT_PROVIDER_TYPES);

type SchedulingOldProps = {
  visitReason: VisitReason | undefined;
};

// eslint-disable-next-line max-statements
export const SchedulingOld = (props: SchedulingOldProps) => {
  const [styles] = useTheme(schedulingThemedStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const searchParams = useLocalSearchParams<SchedulingParams>();
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const isNewMemberRemote = !!appointmentParamsState.isNewMemberRemote;
  const patientState = useRecoilValue(patientAtom);
  const calendarBarRef = useRef<CalendarBarInstanceOld>(null);
  const scrollViewRef = useRef<ScrollViewType>(null);
  const selectedProviderRef = useRef<{ physicianUuid: string; appointmentType: string } | null>(null);
  const visitOutcome = props.visitReason?.visitOutcome;
  const membershipType = useStripeMembership();

  const params = useMemo(() => {
    if (!props.visitReason) {
      return null;
    }

    if (searchParams.showAll === 'true') {
      return {
        is_new_member: isNewMemberRemote,
        visit_reason: props.visitReason.code,
        is_telehealth: isVirtualMembershipProduct(membershipType) || undefined,
      };
    }

    if (!visitOutcome) {
      // Wait for this data to be ready
      return null;
    }

    return {
      is_new_member: isNewMemberRemote,
      is_telehealth: isVirtualMembershipProduct(membershipType) || (visitOutcome.isTelehealth ?? undefined),
      provider_types: visitOutcome.providerTypes ?? undefined,
      visit_reason: props.visitReason.code,
    };
  }, [isNewMemberRemote, searchParams.showAll, visitOutcome, props.visitReason, membershipType]);
  const physiciansResponse = useSWRImmutable(PHYSICIANS_PATH, getPhysicians);
  const {
    availableDates,
    lastLoadedDate: lastLoadedMonthlyDate,
    requestDataAt: requestMonthlyDataAt,
    error: monthlyError,
    retry: monthlyRetry,
  } = useMonthlyRequest(params);
  // The date the user has selected, for which time slots are
  // being displayed
  const [selectedDate, setSelectedDate] = useState(TODAY);
  const {
    error,
    retry,
    data: singleDayData,
    isLoading: isPrimaryLoading,
  } = useSingleDayData(selectedDate, params, visitOutcome);
  const { onSelectTime, onSubmit, isDisclaimerModalVisible, toggleIsDisclaimerModalVisible } = useSelectTime(
    visitOutcome,
    singleDayData
  );

  const selectDateAndScrollToIt = (date: Dayjs, physicianUuid?: string, appointmentType?: string) => {
    setSelectedDate(date);
    calendarBarRef.current?.scrollToDate(date);
    if (physicianUuid && appointmentType) {
      selectedProviderRef.current = { physicianUuid, appointmentType };
    }
  };

  useSetInitialDate(availableDates, selectDateAndScrollToIt);

  const visitDurations = Array.from(
    new Set(
      (singleDayData || []).flatMap(physicianTimeSlots => {
        return physicianTimeSlots.appointmentTypeDetails.duration;
      })
    )
  );

  // Ensure we're alerted if there's more than one visit duration. Should never happen
  useEffect(() => {
    if (visitDurations.length > 1) {
      logError(new Error(`Expected 1 visit duration but received ${visitDurations.length}.`), JSON.stringify(params));
    }
  }, [visitDurations.length, params]);

  const isLoading = physiciansResponse.isLoading || isPrimaryLoading || !props.visitReason;

  useScrollToSelectedAppointmentType({ isLoading, singleDayData, selectedProviderRef, selectedDate, scrollViewRef });

  const notSeenInLastYear = useMemo(
    () =>
      !patientState.lastSeenTimestamp || dayjs(patientState.lastSeenTimestamp).isBefore(dayjs().subtract(1, 'year')),
    [patientState.lastSeenTimestamp]
  );

  const monthlyDateStatus = useCallback(
    (date: Dayjs) => {
      if (date.isAfter(lastLoadedMonthlyDate)) {
        return null;
      }

      return !!availableDates?.[dateAndTimeParsers.toRemoteDate(date)];
    },
    [availableDates, lastLoadedMonthlyDate]
  );

  if (error) {
    return <SchedulingErrorPage onTryAgain={retry} error={error} />;
  }

  if (monthlyError) {
    return <SchedulingErrorPage onTryAgain={monthlyRetry} error={monthlyError} />;
  }

  const monthsOffset = isMobile ? 3 : 2;

  return (
    <>
      <ScrollView
        listenToNativeEvents
        ref={scrollViewRef}
        contentContainerStyle={[styles.container, isMobile && styles.containerMobile]}
        testID="ContainerScrollView"
      >
        <View style={[styles.calendarContainer, isMobile && styles.calendarContainerMobile]}>
          <View style={isDesktop && [styles.schedulingContentDesktop, styles.schedulingContentHeaderDesktop]}>
            <CalendarButton
              onDateSelect={date => {
                setSelectedDate(date);
                calendarBarRef.current?.scrollToDate(date);
              }}
              selectedDate={selectedDate}
              numVisible={monthsOffset}
              availableDates={availableDates}
              requestDataAt={requestMonthlyDataAt}
            />
            <CalendarBarOld
              isAllLoading={!lastLoadedMonthlyDate}
              ref={calendarBarRef}
              selectedDate={selectedDate}
              onSelectDate={date => setSelectedDate(date)}
              onVisibleDateChange={(_, end) => {
                requestMonthlyDataAt(end);
              }}
              dateStatus={monthlyDateStatus}
              visibleDays={MAX_CALENDAR_BAR_DAYS_VISIBLE}
            />
          </View>
        </View>

        <View style={isDesktop && [styles.schedulingContentDesktop, styles.schedulingContentBodyDesktop]}>
          <WaitlistBanner />
          {singleDayData &&
            !isLoading &&
            singleDayData.map(physicianTimeSlots => {
              const physician = physiciansResponse.data?.physicians?.find(
                p => p.uuid === physicianTimeSlots.physician.uuid
              );

              if (!physician) return null;
              if (!physicianTimeSlots.timeSlots?.length) {
                // If this provider has no availability today

                // Hide the card if it's a returning member (who has
                // had an appt within the last year)
                if (!isNewMemberRemote && !notSeenInLastYear) return null;
                // If it's a new member (or hasn't been in in >1yr), show them
                // "Next available" card, but only for MDs (hide for PA/NP/etc)
                if (physician.providerType !== 'MD') return null;
              }

              const appointmentDetails = physicianTimeSlots.appointmentTypeDetails;
              const firstVisitOn = physicianTimeSlots.timeSlots.length
                ? undefined
                : getNextVisitInValue(TODAY, appointmentDetails.location, physician.uuid, availableDates);

              return (
                <AvailabilitySection
                  isPrimary={isAppointmentPrimary(visitOutcome, physicianTimeSlots)}
                  selectedDate={selectedDate}
                  onTimeSlotSelect={visitTime => {
                    return onSelectTime(
                      visitTime,
                      physician,
                      appointmentDetails.appointmentTypeUuid,
                      appointmentDetails.location
                    );
                  }}
                  firstVisitOn={firstVisitOn}
                  onGoToFirstVisitPress={day => {
                    selectDateAndScrollToIt(
                      day,
                      physician.uuid,
                      physicianTimeSlots.appointmentTypeDetails.appointmentTypeUuid
                    );
                  }}
                  key={`${physicianTimeSlots.physician.uuid} ${appointmentDetails.location}`}
                  timeSlots={physicianTimeSlots.timeSlots}
                  appointmentDetails={appointmentDetails}
                  physician={physician}
                  isLoading={false}
                  style={styles.availabilitySection}
                />
              );
            })}

          {/* Put loading indicator after the real items.
           * If initially loading, no real items render so it appears at the top
           * If primary rec already loaded and we're loading secondary recs underneath,
           * we want the loading indicator to show up after the primary recs
           */}
          {(!singleDayData || isLoading) && (
            <>
              <AvailabilitySection isLoading style={styles.availabilitySection} />
              <AvailabilitySection isLoading style={styles.availabilitySection} />
            </>
          )}
        </View>
        <WaitlistFooter />
      </ScrollView>
      {visitOutcome && (
        <DisclaimerModal
          isVisible={isDisclaimerModalVisible}
          onRequestClose={toggleIsDisclaimerModalVisible}
          visitOutcome={visitOutcome}
          onSubmit={() => {
            toggleIsDisclaimerModalVisible();
            onSubmit();
          }}
        />
      )}
    </>
  );
};
