import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import { MOBILE_WIDTH_THRESHOLD, Text, useAdaptive, useTheme } from '@almond/ui';

import { MessageImage } from './MessageImage';

import { themedStyles } from './styles';

import type { ImageDimensions, MessageImageProps } from './MessageImage';
import type { StyleProp, ViewStyle } from 'react-native';

export type MessageWithImageProps = {
  title: string;
  subtitle?: React.ReactNode;
  footer?: React.ReactNode;
  imageProps: Omit<MessageImageProps, 'isMobile' | 'position' | 'dimensions' | 'hiddenCoefficient'> & {
    mobileDimensions: ImageDimensions;
    desktopDimensions: ImageDimensions;
    hiddenCoefficient?: (isMobile: boolean) => number;
  };
  messageContainerStyle?: (isMobile: boolean) => StyleProp<ViewStyle> | undefined;
  breakpoint?: number;
};

export const MessageWithImage = (props: MessageWithImageProps) => {
  const { title, subtitle, footer, imageProps, messageContainerStyle, breakpoint = MOBILE_WIDTH_THRESHOLD } = props;
  const { width } = useWindowDimensions();
  const isMobile = width <= breakpoint;
  const [styles] = useTheme(themedStyles);
  const titleSize = useAdaptive('xxxl', 'xxl');
  const subtitleSize = useAdaptive('m', 's');
  const imagePosition = isMobile ? 'top' : 'right';
  const image = (
    <MessageImage
      {...imageProps}
      isMobile={isMobile}
      position={imagePosition}
      dimensions={isMobile ? imageProps.mobileDimensions : imageProps.desktopDimensions}
      hiddenCoefficient={imageProps.hiddenCoefficient?.(isMobile) ?? 0.2}
    />
  );

  return (
    <View style={isMobile ? styles.containerMobile : styles.containerDesktop}>
      {imagePosition === 'top' && image}
      <View
        style={[
          styles.messageContainer,
          isMobile && styles.messageContainerMobile,
          styles.messageOver,
          messageContainerStyle?.(isMobile),
        ]}
      >
        <Text fontStyle="bold" size={titleSize} style={isMobile && styles.center}>
          {title}
        </Text>
        {subtitle && (
          <Text style={isMobile && styles.center} size={subtitleSize}>
            {subtitle}
          </Text>
        )}
        {!isMobile && footer && <View style={styles.footerDesktop}>{footer}</View>}
      </View>
      {imagePosition === 'right' && image}
      {isMobile && <View style={styles.footerMobile}>{footer}</View>}
    </View>
  );
};
