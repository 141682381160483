export type StringifiedBoolean = 'true' | 'false';

type MarketingParams = {
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
  fbclid: string;
  _gl: string;
};

type ArtemisParams = {
  require_auth: StringifiedBoolean;
  artemis_redirect_url: string;
};

type AppointmentParams = {
  visit_reason: string;
  appointment_uuid: string;
  booking_uuid: string;
  showAll: StringifiedBoolean;
  category: string;
};

type ProfileParams = {
  patient_uuid: string;
  profile_uuid: string;
};

type ReferralParams = {
  refer: string;
  code: string;
};

type ProductParams = {
  product: string;
};

type IntakeFormParams = {
  formType: string;
};

export type BaseParamsWithoutRouting = Partial<
  MarketingParams &
    ArtemisParams &
    AppointmentParams &
    ProfileParams &
    ReferralParams &
    ProductParams &
    IntakeFormParams
>;
