/* eslint-disable max-statements */

import { buildUrlSearchParams } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilCallback } from 'recoil';

import { emailsApi } from '~modules/api';
import { is10thPregnancyVisit, isLabVisit, isProgramVisit } from '~modules/appointment/services';
import { isBaselineProduct, isVirtualMembershipProduct, useStripeProduct } from '~modules/product';
import { appointmentParamsAtom, patientAtom, patientProgressAtom, profileAtom, userAtom } from '~modules/state';

import { log } from '../logger';

import type { EmailTemplates, VisitReasonOut } from '@almond/api-types';

export const useSendEmail = () => {
  const searchParams = useLocalSearchParams();
  const { product } = useStripeProduct();

  return useRecoilCallback(
    callbackInterface =>
      async (bookingUuid?: string, visitReason?: Pick<VisitReasonOut, 'subcategory' | 'code' | 'category'>) => {
        const { isAdmin } = await callbackInterface.snapshot.getPromise(userAtom);
        const patientState = await callbackInterface.snapshot.getPromise(patientAtom);
        const profileState = await callbackInterface.snapshot.getPromise(profileAtom);
        const patientProgressState = await callbackInterface.snapshot.getPromise(patientProgressAtom);
        const appointmentParamsState = await callbackInterface.snapshot.getPromise(appointmentParamsAtom);

        const isNewMemberRemote = !!appointmentParamsState.isNewMemberRemote;

        // We don't need to send any emails for the lab flows.
        if (visitReason && isLabVisit(visitReason)) {
          return log("Lab flow is active, so we don't want to send any emails.");
        }

        if (visitReason && isProgramVisit(visitReason) && !is10thPregnancyVisit(visitReason)) {
          return log('Not sending onboarding email for program visits, except for the 10th group maternity class.');
        }

        if (
          isAdmin &&
          !patientState.isNewMember &&
          patientProgressState.historyData &&
          patientProgressState.consent &&
          patientProgressState.pharmacy &&
          patientProgressState.address &&
          patientProgressState.referral &&
          patientProgressState.subscriptionActive
        ) {
          // Returning patients that have no additional info to fill out don't need an email
          return;
        }

        const sendEmailInParams = {
          email: profileState.email,
          booking_uuid: bookingUuid ?? searchParams.booking_uuid,
        };

        let emailTemplate: EmailTemplates | undefined;

        const membershipEmail = isVirtualMembershipProduct(product) ? 'virtual_welcome_email' : 'gyn_welcome_email';

        if (isBaselineProduct(product)) {
          emailTemplate = 'baseline_welcome_email';
        } else if (visitReason && is10thPregnancyVisit(visitReason)) {
          // Group Maternity #10 - this case differs from the rest because we need to
          // determine if the patient is truly new or not with data that isn't is_new_member.
          // - loginsCount is used to determine if the patient needs to set a password or not.
          // - isNewMemberRemote is used to determine if the patient has had a past visit or not,
          //   which will inform which email template we send.
          emailTemplate = isNewMemberRemote ? membershipEmail : 'tell_us_more';
        } else if (isAdmin && !patientState.isNewMember) {
          // Admin/Returning Patient
          emailTemplate = 'tell_us_more';
        } else if (isAdmin && patientState.isNewMember) {
          // Admin/New Patient
          emailTemplate = membershipEmail;
        } else if (!patientProgressState.emailVerified) {
          // Patient/New Patient
          emailTemplate = patientState.isNewMember ? membershipEmail : 'complete_on_boarding';
        }

        if (!emailTemplate) return;

        await emailsApi.sendEmail(emailTemplate)({
          linkPath: `/get-started${buildUrlSearchParams(sendEmailInParams)}`,
        });
      },
    [product, searchParams.booking_uuid]
  );
};
