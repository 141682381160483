/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Text, useBrowserType, useTheme } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import { useRecoilState } from 'recoil';

import { calculateDiscount, useCheckCode } from '~modules/payment';
import { creditCardAtom } from '~modules/state';

import { CodeEntryForm } from './CodeEntryForm';
import { EnteredCode } from './EnteredCode';
import { LineItem } from './LineItem';
import { ProductIcon } from './ProductIcon';
import { useCheckPCOSMembership } from './useCheckPCOSMembership.ts';

import themedStyles from './styles';

import type { ProductOut } from '@almond/api-types';
import type { useCalculatePrice } from '~modules/payment';
import type { PromotionCode, PromotionCodeNotApplicable, PromotionCodeNotFound, PromotionCodeResult } from '~types';

type SidePanelProps = {
  product: ProductOut;
  mobileRender: (
    content: React.JSX.Element,
    isLoading: boolean,
    isError: boolean,
    totalPrice: number
  ) => React.JSX.Element;
  priceOutput: ReturnType<typeof useCalculatePrice>;
};

const calculateAmount = (
  promotionCode: PromotionCode | undefined,
  promotionCodeType: 'promotionCode' | 'friendReferralCode',
  product: ProductOut,
  priceOutput: ReturnType<typeof useCalculatePrice>
) => {
  if (!promotionCode || promotionCode.state === 'not-found') {
    return 0;
  }

  if (promotionCodeType === 'promotionCode' && promotionCode.code === 'offer35') {
    return -priceOutput.firstMonthFreeDiscount + -calculateDiscount(product, promotionCode);
  }

  if (promotionCodeType === 'promotionCode' && promotionCode.code === 'firstmonthfree') {
    return -priceOutput.firstMonthFreeDiscount;
  }

  return -calculateDiscount(product, promotionCode);
};

const getDiscountCodeTuplesToRender = (
  discountCodeTuples: ['promotionCode' | 'friendReferralCode', PromotionCode | undefined][]
) => {
  const discountCodeTuplesToRender = [...discountCodeTuples];
  const isOffer35 = discountCodeTuplesToRender.some(
    ([, promotionCode]) => promotionCode?.state === 'success' && promotionCode.code.toLowerCase() === 'offer35'
  );

  if (isOffer35) {
    discountCodeTuplesToRender.push([
      'promotionCode',
      {
        amountOff: 0,
        id: '',
        promotionCodeType: 'stripe_discount',
        code: 'firstmonthfree',
        state: 'success',
        isFirstMonthFree: true,
      } satisfies PromotionCodeResult,
    ]);
  }

  return discountCodeTuplesToRender;
};

export const SidePanel: React.FC<SidePanelProps> = props => {
  const { priceOutput, product, mobileRender } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const isMobile = useBrowserType() === 'mobile';

  const [codeError, setCodeError] = useState<null | PromotionCodeNotFound | PromotionCodeNotApplicable>(null);
  const { checkCode, isCheckingCode } = useCheckCode();
  const [creditCardState, setCreditCardState] = useRecoilState(creditCardAtom);
  const discountCodeTuples: ['promotionCode' | 'friendReferralCode', PromotionCode | undefined][] = [
    ['promotionCode', creditCardState.promotionCode],
    ['friendReferralCode', creditCardState.friendReferralCode],
  ];
  const discountCodeTuplesToRender = getDiscountCodeTuplesToRender(discountCodeTuples);
  const discountCodeTuplesRef = useRef(discountCodeTuples);

  discountCodeTuplesRef.current = discountCodeTuples;

  const isRecurring = product?.price.type === 'recurring';
  const totalPrice = priceOutput.discountPrice;
  const renewalPrice = formatPriceInCents(priceOutput.renewalPrice);

  useEffect(() => {
    if (isCheckingCode) {
      return;
    }

    discountCodeTuplesRef.current.forEach(async tuple => {
      const value = tuple[1];

      if (!value || value.state !== 'init') {
        return;
      }

      // Code is entered but not yet loaded - load it
      const result = await checkCode(value.code, tuple);

      if (result?.state === 'not-found' || result?.state === 'not-applicable') {
        setCodeError(result);
        setCreditCardState(prevState => ({ ...prevState, [tuple[0]]: null }));
      }
    });
  }, [checkCode, isCheckingCode, setCreditCardState]);

  useCheckPCOSMembership();

  const content = (
    <View style={styles.sidePanelContainer}>
      <View style={styles.cartItem}>
        <View style={styles.cartItemImageContainer}>
          <ProductIcon />
        </View>
        <View style={styles.cartItemMain}>
          <Text fontStyle="bold" size="m" numberOfLines={1} testID="ProductNameToPurchase">
            {product.name}
          </Text>
        </View>
        <Text fontStyle="bold" size="m">
          {formatPriceInCents(product.price.amount)}
        </Text>
      </View>
      <CodeEntryForm
        isLoading={isCheckingCode || priceOutput.isLoadingPrice}
        onError={code => setCodeError(code)}
        codeError={codeError}
        onCheckCode={checkCode}
      />
      <View style={styles.enteredCodesContainer} testID="PromotionCodeValues">
        {discountCodeTuplesToRender.map(([promotionCodeType, promotionCode], index) => {
          if (promotionCode?.state !== 'success') {
            return null;
          }

          return (
            <EnteredCode
              isDisabled={
                promotionCode.code === 'firstmonthfree' &&
                discountCodeTuplesToRender.filter(([type]) => type === 'promotionCode').length > 1
              }
              promotionCode={promotionCode}
              key={index}
              onDelete={
                promotionCode.code.toLowerCase() === 'offer35'
                  ? () => checkCode('firstmonthfree')
                  : () => setCreditCardState(prevState => ({ ...prevState, [promotionCodeType]: null }))
              }
            />
          );
        })}
      </View>
      <View style={styles.priceContainer}>
        {discountCodeTuples.map(([promotionCodeType, promotionCode]) => {
          return (
            promotionCode &&
            promotionCode.state !== 'not-found' && (
              <LineItem
                key={promotionCode.code}
                size="m"
                title={t(
                  promotionCodeType === 'promotionCode'
                    ? 'creditCard.promotion.discount'
                    : 'creditCard.promotion.referralDiscount'
                )}
                isLoading={promotionCode.state === 'init'}
                amount={calculateAmount(promotionCode, promotionCodeType, product, priceOutput)}
              />
            )
          );
        })}
        {discountCodeTuples.some(([, promotionCode]) => promotionCode) && isRecurring && (
          <View style={styles.priceContainerItem}>
            <View style={styles.priceContainerLeftColumn}>
              <Text size="s">
                {t('creditCard.promotion.renewal', {
                  price: renewalPrice,
                })}
              </Text>
            </View>
          </View>
        )}
        <LineItem
          size="xl"
          title={t('creditCard.promotion.total')}
          isLoading={priceOutput.isLoadingPrice}
          amount={totalPrice}
          amountTestID="TotalPrice"
        />
        {isRecurring && !isMobile && (
          <Text size="m">
            {priceOutput.firstMonthFreeDiscount > 0
              ? t('creditCard.totalExplanationFirstMonthFree')
              : t('creditCard.totalExplanation')}
          </Text>
        )}
      </View>
    </View>
  );

  return isMobile ? mobileRender(content, isCheckingCode, !!codeError, totalPrice) : content;
};
