import React from 'react';

import { useTranslation } from '@almond/localization';
import { Link, Text } from '@almond/ui';
import { getIntakeFormsToComplete } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { appointmentsApi, FUTURE_APPOINTMENTS_PATH, useFocusedSWR } from '~modules/api';
import { useArtemisRedirectUrl, useRedirectBackToArtemis } from '~modules/integration';
import { InterstitialLayout } from '~modules/layout';
import { isBaselineProduct, useStripeProduct } from '~modules/product';
import { patientAtom, patientProgressAtom } from '~modules/state';
import { ConfettiBurstsDecoration } from '~modules/ui';

export const NextStepsPage = () => {
  const { t } = useTranslation();
  const { isNewMember } = useRecoilValue(patientAtom);
  const { data: futureAppointments } = useFocusedSWR(FUTURE_APPOINTMENTS_PATH, appointmentsApi.getFutureAppointments);
  const appointment = futureAppointments?.appointments[0];
  const artemisRedirectUrl = useArtemisRedirectUrl();
  const { backToArtemisLinkProps } = useRedirectBackToArtemis();
  const { allProducts } = useStripeProduct();
  const { products, intakeForms } = useRecoilValue(patientProgressAtom);
  const intakeFormsToComplete = getIntakeFormsToComplete(products, intakeForms);
  const allProductsWithForms = allProducts?.filter(p =>
    intakeFormsToComplete?.some(intakeForm => p.almondProductKey === intakeForm.product)
  );
  const singleProduct = allProductsWithForms?.length === 1 ? allProductsWithForms[0] : null;
  const isBaseline = isBaselineProduct(singleProduct);

  const description = (() => {
    if (isBaseline) {
      return (
        <Text size="l">
          {t('nextSteps.baseline.subtitle.part1')}
          <Link size="l" url={`${artemisRedirectUrl}/settings`}>
            {t('nextSteps.baseline.subtitle.part2')}
          </Link>
          {t('nextSteps.baseline.subtitle.part3')}
        </Text>
      );
    }

    return (
      <Text size="l">
        {t('nextSteps.generic.subtitle.part1')}
        <Text size="l" fontStyle="bold">
          {t('nextSteps.generic.subtitle.part2', { minutes: isNewMember ? '10-15' : '5-10' })}
        </Text>
        {t('nextSteps.generic.subtitle.part3')}
        {appointment ? (
          <Link size="l" url={appointment.rebookingUrl}>
            {t('nextSteps.generic.subtitle.part4')}
          </Link>
        ) : (
          <Text size="l">{t('nextSteps.generic.subtitle.part4')}</Text>
        )}
        {t('nextSteps.generic.subtitle.part5')}
      </Text>
    );
  })();

  return (
    <InterstitialLayout
      backgroundDecoration={<ConfettiBurstsDecoration isCenteredOnDesktop />}
      id="nextSteps"
      title={t('nextSteps.title')}
      description={description}
      submitButtonProps={{
        ...backToArtemisLinkProps(),
        testID: 'Submit',
        children: t('nextSteps.submit'),
      }}
      secondaryButtonProps={{
        ...backToArtemisLinkProps('/messages'),
        testID: 'Secondary',
        children: t('nextSteps.secondary'),
      }}
    />
  );
};
