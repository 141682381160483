import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  background: {
    backgroundColor: theme.lightAccent,
  },
  desktopContainer: {
    paddingHorizontal: 60,
  },
  mobileContainer: {
    maxWidth: 340,
    marginHorizontal: 'auto',
    height: '100%',
  },
  mobileInnerContainer: {
    flexGrow: 1,
    gap: 30,
  },
  center: {
    textAlign: 'center',
  },
  image: {
    width: 500,
    height: 300,
    transform: [{ scaleX: -1 }, { translateY: '-50%' }, { translateX: '-50%' }],
    position: 'absolute',
    top: 'calc(50% + 180px)' as any,
    right: 0,
  },
  buttonContainer: {
    flexDirection: 'row',
    columnGap: sizes.SPACING_M,
    rowGap: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  button: {
    width: 240,
    maxWidth: '100%',
  },
}));
