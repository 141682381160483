import { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { patientsApi } from '~modules/api';
import { patientProgressAtom } from '~modules/state';

import type { FormValues } from '@almond/ui';
import type { ReferralFormValues } from '~types';

const useAddReferral = (): ((values: FormValues<ReferralFormValues>) => Promise<void>) => {
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);

  return useCallback(
    async (values: FormValues<ReferralFormValues>) => {
      await patientsApi.addReferral({ referral: values.referral, referredByName: values.referrerByName });
      setPatientProgressState(prevState => ({ ...prevState, referral: true }));
    },
    [setPatientProgressState]
  );
};

export default useAddReferral;
