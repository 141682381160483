import type { VisitReasonOut } from '@almond/api-types';

export const isGroupMaternityProgram = (visitReason: Pick<VisitReasonOut, 'subcategory'>) => {
  return visitReason.subcategory === 'Group Maternity Program';
};

export const is10thPregnancyVisit = (visitReason: Pick<VisitReasonOut, 'code'>) => {
  return visitReason.code === 'group_pregnancy_care_10';
};

export const isLabVisit = (visitReason: Pick<VisitReasonOut, 'code'>) => {
  return visitReason.code === 'assisted_lab';
};

export const isProgramVisit = (visitReason: Pick<VisitReasonOut, 'category'>) => {
  return visitReason.category !== 'general';
};
