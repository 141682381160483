import { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { patientsApi } from '~modules/api';
import { patientProgressAtom } from '~modules/state';

import type { FormValues } from '@almond/ui';
import type { PharmacyFormValues } from '~types';

const useCreatePharmacy = (): ((values: FormValues<PharmacyFormValues>) => Promise<void>) => {
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);

  return useCallback(
    async (values: FormValues<PharmacyFormValues>) => {
      const { name, ...restPharmacy } = values.pharmacy;

      await patientsApi.createPharmacy({ name, address: { ...restPharmacy } });

      setPatientProgressState(prevState => ({ ...prevState, pharmacy: true }));
    },
    [setPatientProgressState]
  );
};

export default useCreatePharmacy;
