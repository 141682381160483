import { useMemo } from 'react';

import { useGlobalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';
import useSWRImmutable from 'swr/immutable';
import { z } from 'zod';

import { PRODUCTS_PATH, productsApi } from '~modules/api';
import { patientProgressAtom } from '~modules/state';

import type { PatientProductOut, ProductOut } from '@almond/api-types';

// Handle invalid product key values
const productKeyThatCanBeBoughtInDemiSchema = z.enum(['baseline', 'gynecology_membership', 'virtual_membership']);

type PatientProductOutThatCanBeBoughtInDemi = Omit<PatientProductOut, 'almondProductKey'> & {
  almondProductKey: z.infer<typeof productKeyThatCanBeBoughtInDemiSchema>;
};

export const useStripeProduct = () => {
  // Since this hook is used in Stripe Elements component, must be global not local search params
  const searchParams = useGlobalSearchParams();
  const { subscription, subscriptionActive, products } = useRecoilValue(patientProgressAtom);

  const parsedProductSearchParams = useMemo(
    () => productKeyThatCanBeBoughtInDemiSchema.safeParse(searchParams.product).data,
    [searchParams.product]
  );

  // If user has an existing subscription (active or expired), use that as the product.
  // Otherwise, use the product from the searchParams.
  // In either case, if baseline is the product in the URL, use that.
  // This prevents a user from specifying a different membership type in the URL than they signed up for.
  let productKey: z.infer<typeof productKeyThatCanBeBoughtInDemiSchema>;

  if (parsedProductSearchParams) {
    // If you're using the product in the URL, you must not have a membership. If you have a virtual/classic membership,
    // you can't purchase anything else (i.e. baseline).
    // However, if you have only purchased baseline (not a membership), you CAN still purchase a membership (virtual
    // would need to be specified in the URL).
    if (subscription?.membershipType && subscriptionActive) {
      // Ignore the URL product param
      productKey = subscription.membershipType;
    } else {
      productKey = parsedProductSearchParams;
    }
  } else {
    // Only allow baseline, virtual, or gynecology_membership to be purchased
    const unpurchasedProduct = products?.find(
      (p): p is PatientProductOutThatCanBeBoughtInDemi =>
        p.status === 'pending' && productKeyThatCanBeBoughtInDemiSchema.safeParse(p.almondProductKey).success
    );

    if (unpurchasedProduct) {
      productKey = unpurchasedProduct.almondProductKey;
    } else if (subscription?.membershipType) {
      productKey = subscription.membershipType;
    } else {
      productKey = 'gynecology_membership';
    }
  }

  const { data, isLoading, error, mutate, ...restResponse } = useSWRImmutable(PRODUCTS_PATH, productsApi.getProducts);

  const product = useMemo(() => {
    const foundProduct = data?.products.find(p => p.almondProductKey === productKey);

    if (!foundProduct) {
      return foundProduct;
    }

    return foundProduct as Omit<ProductOut, 'almondProductKey'> & {
      almondProductKey: typeof productKey;
    };
  }, [data, productKey]);

  return {
    product,
    isLoadingProduct: isLoading || !productKey,
    productError: error,
    retryProduct: mutate,
    allProducts: data?.products,
    ...restResponse,
  };
};
