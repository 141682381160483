import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { IconButton, sizes, Text, useTheme } from '@almond/ui';

import themedStyles from './styles.ts';

import type { PromotionCode } from '~types';

export type EnteredCodeProps = {
  promotionCode: PromotionCode;
  isDisabled?: boolean;
  onDelete?: () => any;
};

const ICON_SIZE = sizes.SPACING_M;

export const EnteredCode = (props: EnteredCodeProps) => {
  const { promotionCode, isDisabled, onDelete } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();

  return (
    <View style={styles.enteredCode} key={promotionCode.code}>
      <Text size="s" style={styles.enteredCodeText}>
        {promotionCode.code}
      </Text>
      {isDisabled ? (
        <View style={{ width: ICON_SIZE, height: ICON_SIZE }} />
      ) : (
        <IconButton
          testID="PromotionCodeRemove"
          aria-label={t('creditCard.promotion.removeCode', { code: promotionCode.code })}
          onPress={onDelete}
          style={styles.enteredCodeButton}
          source="close"
          size={ICON_SIZE}
          color="secondaryTextDark"
          isDisabled={isDisabled}
        />
      )}
    </View>
  );
};
