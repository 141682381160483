import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Text, useTheme } from '@almond/ui';

import { ErrorMessage } from '~modules/errors';

import { BaseModal, type BaseModalProps } from '../BaseModal';
import { JoinAlmondButton } from './JoinAlmondButton';

import { themedStyles } from './styles';

import type { useCalculatePrice } from '~modules/payment';

export type NotSureAboutCostModalProps = Omit<BaseModalProps, 'subtitle'> & {
  onSubmit: () => void;
  priceOutput: ReturnType<typeof useCalculatePrice>;
  isLoading?: boolean;
  joinAlmondButtonTestID?: string;
  error?: Error;
};

export const NotSureAboutCostModal = (props: NotSureAboutCostModalProps) => {
  const { onSubmit, title, priceOutput, joinAlmondButtonTestID, isLoading, error, ...restProps } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const content = (() => {
    if (priceOutput.isLoadingPrice || isLoading) {
      return <ActivityIndicator />;
    }

    if (priceOutput.priceError || error) {
      return <ErrorMessage error={priceOutput.priceError || error} onTryAgain={priceOutput.retryPrice} />;
    }

    return (
      <JoinAlmondButton
        testID={joinAlmondButtonTestID}
        totalPrice={priceOutput.fullPrice}
        discountedPrice={priceOutput.discountPrice}
        onPress={onSubmit}
      />
    );
  })();

  return (
    <BaseModal
      title={title}
      containerStyle={styles.modal}
      subtitle={t('notSureAboutCostModal.subtitle')}
      {...restProps}
    >
      <View style={styles.modalContent}>{content}</View>
      <Text size="m" style={styles.bottomText}>
        {t('notSureAboutCostModal.bottomText')}
      </Text>
    </BaseModal>
  );
};
