import React, { useMemo } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, IconButton, useTheme } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { checkIfPostBookingFlow, useRouteNavigation } from '~modules/routing';
import { userAtom } from '~modules/state';

import { AbandonModalWrapper } from '../AbandonModalWrapper';
import { ConfirmationModal } from './ConfirmationModal';
import { useCloseButton } from './useCloseButton';

import themedStyles from './styles';

export type CloseButtonProps = {
  isVisible?: boolean;
  showAsIconButton?: boolean;
};

const CloseButton: React.FC<CloseButtonProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const buttonContainerStyle = props.showAsIconButton ? {} : styles.buttonContainer;
  const { isAdmin } = useRecoilValue(userAtom);
  const { onPress, text, isModalVisible, toggleModal, onLeavePress } = useCloseButton();
  const closeButtonComponent = props.showAsIconButton ? (
    <IconButton source="close" color="text" onPress={onPress} />
  ) : (
    <Button
      onPress={onPress}
      size="s"
      fontStyle="bold"
      type="mediumAccent"
      fixedWidth={false}
      style={styles.buttonContainer}
    >
      {t(text)}
    </Button>
  );

  const { routingConfigId } = useRouteNavigation();
  const isPostBookingFlow = checkIfPostBookingFlow(routingConfigId);
  const modals = useMemo(() => {
    if (isPostBookingFlow || isAdmin) {
      return <ConfirmationModal isVisible={isModalVisible} onRequestClose={toggleModal} onLeavePress={onLeavePress} />;
    }

    return <AbandonModalWrapper isVisible={isModalVisible} onRequestClose={toggleModal} onLeave={onLeavePress} />;
  }, [isPostBookingFlow, isAdmin, isModalVisible, onLeavePress, toggleModal]);

  if (!props.isVisible) return <View style={buttonContainerStyle} testID="FakeButton" />;

  return (
    <View style={buttonContainerStyle} testID="Close">
      {closeButtonComponent}
      {modals}
    </View>
  );
};

export default CloseButton;
