import { PATIENTS_PATH, UUID_TEMPLATE } from '../config';

export const APPOINTMENTS_PATH = `${PATIENTS_PATH}appointments/` as const;

export const BOOKING_PATH = (appointmentUuid: string) =>
  `${APPOINTMENTS_PATH}${appointmentUuid as '{appointment_uuid}'}/booking` as const;

export const LAB_SUBMIT_PATH = (appointmentUuid: string) =>
  `${APPOINTMENTS_PATH}${appointmentUuid as '{appointment_uuid}'}/lab_orders` as const;

export const APPOINTMENT_PATH = (appointmentUuid: string) =>
  `${APPOINTMENTS_PATH}${appointmentUuid as '{appointment_uuid}'}/` as const;

export const PAST_COUNT_PATH = (patientUuid: string) =>
  `${APPOINTMENTS_PATH.replace(UUID_TEMPLATE, patientUuid) as typeof APPOINTMENTS_PATH}past_count` as const;

export const FUTURE_APPOINTMENTS_PATH = `${APPOINTMENTS_PATH}future_appointments` as const;
