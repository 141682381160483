import { getIntakeFormsToComplete } from '@almond/utils';
import { useRecoilValue } from 'recoil';

import { patientProgressAtom } from '~modules/state';

export const useIntakeFormToComplete = (formType: string | undefined) => {
  const { products, intakeForms } = useRecoilValue(patientProgressAtom);

  if (!formType) {
    return products
      ?.flatMap(p => p.intakeForms.map(f => ({ ...f, product: p.almondProductKey })))
      .find(f => f.type === 'msq');
  }

  const allIntakeForms = getIntakeFormsToComplete(products, intakeForms);

  return allIntakeForms.find(form => form.type === formType);
};
