import React, { useCallback } from 'react';

import { COMPLETED_DURING_CURRENT_SESSION, cypressUtilities } from '@almond/utils';
import { useLocalSearchParams } from 'expo-router';
import { useSetRecoilState } from 'recoil';

import { patientsApi } from '~modules/api';
import { useIntakeFormToComplete, useRouteNavigation } from '~modules/routing';
import { patientProgressAtom } from '~modules/state';

import { IntakeFormNotFound, TypeformWidget } from '../../components';
import { MockWidget } from './MockWidget';

import type { INTAKE_FORM_PAGE_NAME } from '~types';

const IS_PROD = process.env.EXPO_PUBLIC_ENV === 'prod';
const USE_MOCK_WIDGET = process.env.EXPO_PUBLIC_MOCK_TYPEFORM === 'true' || cypressUtilities.isCypressRunning();

export const IntakeFormPage = () => {
  // TODO have we figured out how to handle back button?
  // https://github.com/almondobgyn/frontend/pull/1649#discussion_r1855301427
  const { formType } = useLocalSearchParams();
  const { dispatch } = useRouteNavigation<typeof INTAKE_FORM_PAGE_NAME>();
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);

  const currentIntakeForm = useIntakeFormToComplete(formType);

  const onSubmit = useCallback(async () => {
    if (!currentIntakeForm?.type) {
      return;
    }

    // Update patient progress state to show that this form has been submitted
    setPatientProgressState(prev => ({
      ...prev,
      intakeForms: [
        ...(prev.intakeForms ?? []),
        {
          createdAt: COMPLETED_DURING_CURRENT_SESSION,
          formType: currentIntakeForm.type,
        },
      ],
    }));

    if (currentIntakeForm.type === 'baseline_intake') {
      await patientsApi.sendSystemMessage('baseline_intake_completion');
    }

    dispatch('submit');
  }, [currentIntakeForm?.type, dispatch, setPatientProgressState]);

  if (!currentIntakeForm) {
    return <IntakeFormNotFound />;
  }

  return (
    <TypeformWidget
      testID="intakeForm"
      isProd={IS_PROD}
      id={currentIntakeForm.formId}
      onSubmit={onSubmit}
      MockWidget={USE_MOCK_WIDGET ? MockWidget : undefined}
    />
  );
};
