import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  container: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.lightSecondaryBackground,
    height: 62,
  },
  containerMobile: {
    height: 52,
  },
  fakeButton: {
    minWidth: 48,
  },
  headerTextMobile: {
    fontSize: sizes.FONT_SIZE_XL,
  },
  logoDesktop: {
    width: 91,
    height: 20,
  },
  logoMobile: {
    width: 68,
    height: 15,
  },
  headerText: {
    color: theme.accent,
  },
  loadingContainer: {
    width: '30%',
  },
  row: {
    flexDirection: 'column',
    rowGap: sizes.SPACING_XS,
  },
  buttonContainer: {
    width: 160,
  },
  buttonContainerLeft: {
    alignItems: 'flex-start',
    marginLeft: sizes.SPACING_S,
  },
  absoluteContainer: {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: 1,
  },

  leftContainer: {
    flex: 1,
  },
  centerContainer: {
    flex: 1,
    alignItems: 'center',
  },
  rightContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  rightContentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: sizes.SPACING_M,
    paddingRight: 20,
  },
  rightContentContainerMobile: {
    gap: sizes.SPACING_S,
    paddingRight: sizes.SPACING_S,
  },
}));
