export const ALL_SET_PAGE_NAME = '/all-set' as const;
export const CONSENT_SIGNATURE_PAGE_NAME = '/consent-signature' as const;
export const CONFIRM_PURCHASE_PAGE_NAME = '/confirm-purchase' as const;
export const CREATE_PATIENT_PAGE_NAME = '/create-patient' as const;
export const GET_STARTED_PAGE_NAME = '/get-started' as const;
export const PATIENT_LOGIN_PAGE_NAME = '/login' as const;

export type TypeformResponseParams = {
  outcome: 'npPaVisit' | 'reviewing' | 'urgentCare';
  typeform_type: 'infection_questionnaire' | 'baseline_intake';
};

export type PatientParams = {
  [ALL_SET_PAGE_NAME]: undefined;
  [CONFIRM_PURCHASE_PAGE_NAME]: undefined;
  [CONSENT_SIGNATURE_PAGE_NAME]: undefined;
  [CREATE_PATIENT_PAGE_NAME]: undefined;
  [GET_STARTED_PAGE_NAME]: undefined;
  [PATIENT_LOGIN_PAGE_NAME]: undefined;
};
