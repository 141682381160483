import { useMemo } from 'react';

import { useEvent } from '@almond/utils';
import { useGlobalSearchParams, useSegments } from 'expo-router';

import { useRouteContext } from '../context/routeContext';
import { useNavigateTo } from './useNavigateTo';

import type routeConfigs from '../configurations';
import type { ConfigIdFromPageName, Dispatch, GetEvents, RouteConfig, RoutingConfig, StackParamList } from '~types';

type DefaultInferredRouteConfig = (typeof routeConfigs)[keyof typeof routeConfigs];

export const useRouteNavigation = <
  RouteName extends Extract<keyof StackParamList, string> = Extract<keyof StackParamList, string>,
  InferredRouteConfig extends RoutingConfig<StackParamList> = DefaultInferredRouteConfig,
>(): {
  // Keep parens because otherwise it breaks VS Code's syntax highlighting for
  // the rest of the file
  // prettier-ignore
  dispatch: Dispatch<(GetEvents<StackParamList, InferredRouteConfig, RouteName>)>;
} & (
  | {
      isRoutingActive: true;
      routingConfigId: ConfigIdFromPageName<StackParamList, InferredRouteConfig, RouteName>;
    }
  | { isRoutingActive: false; routingConfigId: undefined }
) => {
  const navigateTo = useNavigateTo();
  const pathnameWithGroups = `/${useSegments().join('/')}`;
  const searchParams = useGlobalSearchParams();
  const routingConfigMap = useRouteContext<typeof routeConfigs>();

  const dispatch: Dispatch<GetEvents<StackParamList, InferredRouteConfig, RouteName>> = useEvent(
    async (eventStr, params, sp): Promise<void> => {
      await navigateTo(eventStr, pathnameWithGroups, { ...searchParams, ...sp }, params);
    }
  );

  // Using useMemo() as an IIFE to short circuit with `return`
  const isRoutingActive = useMemo(() => {
    const currentRoutingConfigId = searchParams.routing_config_id;

    if (!currentRoutingConfigId) {
      return false;
    }

    const routingConfig: DefaultInferredRouteConfig | undefined =
      routingConfigMap[currentRoutingConfigId as unknown as keyof typeof routeConfigs];

    if (!routingConfig) {
      return false;
    }

    const currentRouteConfig: RouteConfig<StackParamList> | undefined = (routingConfig.routes as any)[
      pathnameWithGroups
    ];

    if (!currentRouteConfig || currentRouteConfig.on === null) {
      return false;
    }

    return true;
  }, [pathnameWithGroups, routingConfigMap, searchParams]);

  if (isRoutingActive) {
    return {
      dispatch,
      isRoutingActive,
      routingConfigId: searchParams.routing_config_id as ConfigIdFromPageName<
        StackParamList,
        InferredRouteConfig,
        RouteName
      >,
    };
  }

  return { dispatch, isRoutingActive, routingConfigId: undefined };
};
