import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  containerDesktop: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  containerMobile: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  messageContainer: {
    ...getShadow(),
    backgroundColor: theme.background,
    flex: 1,
    minWidth: 440,
    width: 530,
    borderRadius: sizes.SPACING_S,
    justifyContent: 'center',
    padding: 64,
    rowGap: sizes.SPACING_M,
  },

  messageContainerMobile: {
    minWidth: 0,
    width: '90%',
    alignItems: 'center',
    paddingHorizontal: sizes.SPACING_XXL,
    paddingVertical: sizes.SPACING_XL,
  },

  messageOver: {
    zIndex: 1,
  },

  center: {
    textAlign: 'center',
  },

  footerDesktop: {
    marginTop: sizes.SPACING_M,
  },

  footerMobile: {
    marginTop: sizes.SPACING_XL,
  },
}));

export const messageImageStyles = styleSheetFactory(() => ({
  containerDesktop: {
    zIndex: -1,
  },

  containerMobile: {
    zIndex: 2,
  },
}));
