import React from 'react';

import { useTranslation } from '@almond/localization';
import { useToastNotification } from '@almond/ui';
import { formatPriceInCents } from '@almond/utils';
import useSWR from 'swr';

import { productsApi, PROMOTION_CODES } from '~modules/api';
import { calculateDiscount, useCalculatePrice, useCheckCode } from '~modules/payment';
import { useStripeProduct } from '~modules/product';

import { NotSureAboutCostModal } from '../NotSureAboutCostModal';

import type { NotSureAboutCostModalProps } from '../NotSureAboutCostModal';

export type AdditionalOfferModalProps = Omit<
  NotSureAboutCostModalProps,
  'title' | 'priceOutput' | 'onSubmit' | 'isLoading' | 'joinAlmondButtonTestID'
>;

export const AdditionalOfferModal = (props: AdditionalOfferModalProps) => {
  const { onRequestClose, onBack, ...restProps } = props;
  const { t } = useTranslation();
  const { product } = useStripeProduct();
  const { checkCode } = useCheckCode();
  const { showToast } = useToastNotification();
  const {
    data: promotionCode,
    error,
    isLoading: isCheckingCode,
  } = useSWR(props.isVisible ? [PROMOTION_CODES, 'offer35', product?.almondProductKey] : null, async ([_, ...args]) =>
    productsApi.checkPromotionCode(...args)
  );
  const priceOutput = useCalculatePrice(product, promotionCode);

  const handleBack = () => {
    onBack?.();
  };

  const handleRequestClose = () => {
    onRequestClose?.();
  };

  const handleSubmit = () => {
    showToast({ text: t('additionalOfferModal.toastText'), type: 'info', duration: 3200 });
    checkCode('offer35');
    onRequestClose?.();
  };

  const title = (() => {
    if (!product || isCheckingCode) {
      return '';
    }

    return t('additionalOfferModal.title', {
      price: product ? formatPriceInCents(calculateDiscount(product, promotionCode), true) : 0,
    });
  })();

  return (
    <NotSureAboutCostModal
      onRequestClose={handleRequestClose}
      onBack={handleBack}
      isLoading={isCheckingCode}
      title={title}
      joinAlmondButtonTestID="AdditionalOfferModal-Submit"
      priceOutput={priceOutput}
      onSubmit={handleSubmit}
      error={error}
      {...restProps}
    />
  );
};
