import React, { useMemo } from 'react';

import { useTranslation } from '@almond/localization';
import { PulseProvider } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';
import { useGate } from 'statsig-react';

import { ErrorMessage } from '~modules/errors';
import { JotaiForm } from '~modules/forms';
import { Container } from '~modules/layout';
import { usePromotionCodeNotificationStatus, useReferralCodeNotificationStatus } from '~modules/payment';
import { appointmentParamsAtom, patientAtom } from '~modules/state';
import { NotificationStatusBar } from '~modules/ui';

import { useSubmitVisitReason, useVisitReasons } from '../../hooks';
import { NewMemberVisitReasonsNew } from './NewMemberVisitReasonsNew';
import { NewMemberVisitReasonsOld } from './NewMemberVisitReasonsOld';
import { VisitReasons } from './VisitReasons';

export const VisitReasonsPage: React.FC = () => {
  const { t } = useTranslation();
  const { visitReasons, isLoadingVisitReasons, visitReasonsError, mutateVisitReasons } = useVisitReasons();
  const submitVisitReason = useSubmitVisitReason(visitReasons);
  const { isNewMemberRemote } = useRecoilValue(appointmentParamsAtom);
  const { isNewMember } = useRecoilValue(patientAtom);
  const searchParams = useLocalSearchParams();
  const referralNotificationStatus = useReferralCodeNotificationStatus();
  const promotionNotificationStatus = usePromotionCodeNotificationStatus();
  const activeVisitReasons = useMemo(() => visitReasons?.filter(v => v.isActive), [visitReasons]);

  const showNewMemberVisitReasonsUi = (isNewMember || isNewMemberRemote) && !searchParams.category;
  const { value: isSchedulingV2Enabled } = useGate('scheduling_v2');
  // Show ungrouped to self-booked new members (<NewMemberVisitReasons>). Returning members,
  // show the grouped view (<VisitReasons>)
  const ContentComponent = showNewMemberVisitReasonsUi ? NewMemberVisitReasonsOld : VisitReasons;

  if (visitReasonsError) {
    return <ErrorMessage error={visitReasonsError} onTryAgain={() => mutateVisitReasons()} />;
  }

  if (isSchedulingV2Enabled && showNewMemberVisitReasonsUi) {
    return (
      <PulseProvider duration={1500}>
        {referralNotificationStatus && <NotificationStatusBar {...referralNotificationStatus} />}
        {promotionNotificationStatus && <NotificationStatusBar {...promotionNotificationStatus} />}
        <NewMemberVisitReasonsNew
          isLoading={isLoadingVisitReasons}
          visitReasons={activeVisitReasons}
          onSubmit={submitVisitReason}
        />
      </PulseProvider>
    );
  }

  return (
    <PulseProvider duration={1500}>
      <JotaiForm atom={appointmentParamsAtom}>
        <Container
          id="visitReasons"
          title={t('visitReasons.title')}
          size="L"
          statusBar={
            <>
              {referralNotificationStatus && <NotificationStatusBar {...referralNotificationStatus} />}
              {promotionNotificationStatus && <NotificationStatusBar {...promotionNotificationStatus} />}
            </>
          }
        >
          <ContentComponent
            isLoading={isLoadingVisitReasons}
            visitReasons={activeVisitReasons}
            onSubmit={submitVisitReason}
          />
        </Container>
      </JotaiForm>
    </PulseProvider>
  );
};
