import { getIntakeFormsToComplete } from '@almond/utils';

import { patientProgressAtom } from '~modules/state';
import {
  ADDRESS_PAGE_NAME,
  ALL_SET_PAGE_NAME,
  INTAKE_FORM_INTRO_PAGE_NAME,
  NEXT_STEPS_PAGE_NAME,
  PHARMACY_PAGE_NAME,
  REFERRAL_PAGE_NAME,
} from '~types';

import type { PatientProgressState } from '~types';
import type { RecoilState } from 'recoil';

export const getPatientHistoryProgress = (patientProgressState: PatientProgressState) => {
  const { pharmacy, address, referral, intakeForms, products } = patientProgressState;

  if (!pharmacy) return { name: PHARMACY_PAGE_NAME };
  if (!address) return { name: ADDRESS_PAGE_NAME };
  if (!referral) return { name: REFERRAL_PAGE_NAME };

  const intakeFormsToComplete = getIntakeFormsToComplete(products, intakeForms).filter(f => !f.completed);

  if (intakeFormsToComplete.length > 0) {
    return { name: INTAKE_FORM_INTRO_PAGE_NAME, params: { formType: intakeFormsToComplete[0].type } };
  }

  return { name: NEXT_STEPS_PAGE_NAME };
};

export const patientHistoryProgress = async (getState: <T>(recoilValue: RecoilState<T>) => Promise<T>) => {
  const patientProgressState = await getState(patientProgressAtom);

  return getPatientHistoryProgress(patientProgressState);
};

// We should only go to historyRoutes if there is at least one page that we
// will show to the user.
// If there are 0 pages, just go to ALL_SET_PAGE_NAME, which results in
// redirecting to Artemis.
export const patientHistoryProgressWithShortCircuit = async <Routes>(
  getState: <T>(recoilValue: RecoilState<T>) => Promise<T>,
  historyRoutes: Routes
) => {
  const patientProgressState = await getState(patientProgressAtom);

  const nextState = getPatientHistoryProgress(patientProgressState);

  if (patientProgressState.historyData && nextState.name === NEXT_STEPS_PAGE_NAME) {
    return { name: ALL_SET_PAGE_NAME };
  }

  return historyRoutes;
};
