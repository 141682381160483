import React, { useEffect } from 'react';

import { setHeapMemberType } from '@almond/analytics';
import { ActivityIndicator } from '@almond/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilValue } from 'recoil';

import { usersApi } from '~modules/api';
import { useDetermineFirstMonthFree } from '~modules/profile';
import { useAsync } from '~modules/promises';
import { getAllowedInitialSearchParams, useRouteNavigation } from '~modules/routing';
import { userAtom } from '~modules/state';
import { Background } from '~modules/ui';

import type { PATIENT_LOGIN_PAGE_NAME } from '~types';

export const PatientLoginPage: React.FC = () => {
  const { doAsync } = useAsync();
  const { dispatch } = useRouteNavigation<typeof PATIENT_LOGIN_PAGE_NAME>();
  const { isLoading: isAuth0Loading, isAuthenticated } = useAuth0();

  const userState = useRecoilValue(userAtom);

  useDetermineFirstMonthFree(false);

  useEffect(() => {
    if (isAuth0Loading || !isAuthenticated) return;

    const toCall = async (): Promise<void> => {
      // If `hasRedirect` is specified then it means that the user has just set a new password,
      // so we need to consume the redirect and process the url params.

      setHeapMemberType('established');

      if (!userState.user?.hasRedirect) {
        return dispatch('signin');
      }

      // Question - do we actually need the redirect value? Or is the boolean enough? Can we "consume" it (delete it)
      // here, but ignore the response?
      // Answer - after talking with Tanay, looks like we need the product ID, and possibly the
      // booking/appointment IDs
      // EDIT - we actually probably don't need the product. If we do, it would be a problem for the
      // link shown in "Next Steps" widget in Artemis
      const response = await usersApi.consumeRedirect();
      const { searchParams: urlSearchParams } = new URL(response.url);
      const initialSearchParams = getAllowedInitialSearchParams(urlSearchParams);

      await dispatch('signin', undefined, initialSearchParams);
    };

    doAsync(toCall);
  }, [dispatch, doAsync, isAuth0Loading, isAuthenticated, userState.user?.hasRedirect]);

  return (
    <Background>
      <ActivityIndicator />
    </Background>
  );
};
