import { useRecoilValue } from 'recoil';
import useSWR from 'swr';

import { productsApi } from '~modules/api';
import { creditCardAtom, patientProgressAtom } from '~modules/state';

import { calculateDiscount, isPromotionCodeRepeating } from '../services';

import type { ProductOut } from '@almond/api-types';
import type { PromotionCode, PromotionCodeResult } from '~types';

export const useCalculatePrice = (
  product: Pick<ProductOut, 'price'> | undefined,
  promotionCodeToUse?: PromotionCode
) => {
  const creditCardState = useRecoilValue(creditCardAtom);
  const patientProgressState = useRecoilValue(patientProgressAtom);

  const isCodeLoading =
    creditCardState.promotionCode?.state === 'init' || creditCardState.friendReferralCode?.state === 'init';

  let promotionCode: PromotionCodeResult | undefined;
  let friendReferralCode: PromotionCodeResult | undefined;

  if (promotionCodeToUse && promotionCodeToUse.state === 'success') {
    promotionCode = promotionCodeToUse;
  } else if (creditCardState.promotionCode?.state === 'success') {
    promotionCode = creditCardState.promotionCode;
  }

  if (creditCardState.friendReferralCode?.state === 'success') {
    friendReferralCode = creditCardState.friendReferralCode;
  }

  const {
    data: price,
    error: priceError,
    isLoading: isLoadingPrice,
    mutate: retryPrice,
  } = useSWR(
    isCodeLoading || !product?.price.id ? null : [product.price.id, promotionCode, friendReferralCode],
    ([priceId, promotionCodeArg, friendReferralCodeArg]) => {
      const firstMonthFreeForRequest = promotionCodeArg?.state === 'success' && promotionCodeArg.isFirstMonthFree;
      const promotionCodeForRequest = promotionCodeArg?.code === 'firstmonthfree' ? undefined : promotionCodeArg?.id;
      const friendReferralCodeForRequest = friendReferralCodeArg?.id;

      return productsApi.calculatePrice(priceId)({
        firstMonthFree: firstMonthFreeForRequest,
        promotionCode: promotionCodeForRequest,
        friendReferralCode: friendReferralCodeForRequest,
      });
    },
    { revalidateOnFocus: false }
  );

  if (product && price) {
    const { fullPrice = 0, discountPrice = 0 } = price;
    const repeatingCodes = [promotionCode, friendReferralCode].filter(code => isPromotionCodeRepeating(code));
    const renewalPrice = repeatingCodes.reduce((acc, cur) => {
      if (!cur) {
        return acc;
      }

      return acc - calculateDiscount(product, cur);
    }, fullPrice);
    const firstMonthFreeDiscount =
      fullPrice -
      discountPrice -
      calculateDiscount(product, promotionCode) -
      calculateDiscount(product, friendReferralCode);

    return {
      fullPrice,
      discountPrice,
      renewalPrice,
      firstMonthFreeDiscount,
      // If both "product" and "price" are specified, then it means that the responses are correct, and we can return
      // "priceError: null" and "isLoadingPrice: false".
      priceError: null,
      isLoadingPrice: false,
      retryPrice,
    };
  }

  return {
    fullPrice: product?.price.amount ?? 0,
    discountPrice: product?.price.amount ?? 0,
    renewalPrice: product?.price.amount ?? 0,
    firstMonthFreeDiscount: 0,
    priceError,
    // Don't trigger "isLoadingPrice" for patients who have an active subscription. We don't need to display any
    // payments-related information for them.
    isLoadingPrice: patientProgressState.subscriptionActive ? false : isLoadingPrice,
    retryPrice,
  };
};
