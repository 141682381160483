import React from 'react';
import { View } from 'react-native';

import { PulseProvider } from '@almond/ui';

import { ErrorMessage } from '~modules/errors';

import { LoadingProvider, Provider } from './Provider';
import { unstable_styles as cssStyles } from './provider.module.css';
import { useGetPhysicians } from './useGetPhysicians';

import type { VisitOutcome } from '../../services';

type ProvidersProps = {
  visitOutcome: VisitOutcome;
  visitReason: string | undefined;
};

export const Providers = (props: ProvidersProps) => {
  const { visitOutcome, visitReason } = props;
  const { data, isLoading, error, mutate } = useGetPhysicians();

  if (isLoading || !visitReason) {
    return (
      <View style={cssStyles.providers}>
        <PulseProvider duration={1000}>
          <LoadingProvider />
          <LoadingProvider />
          <LoadingProvider />
        </PulseProvider>
      </View>
    );
  }

  if (error) {
    return <ErrorMessage error={error} onTryAgain={() => mutate()} isFull={false} />;
  }

  const physicians = data?.physicians?.filter(p => {
    // Only include physicians that support the currently-selected visit reason
    if (!p.visitReasons.includes(visitReason)) {
      return false;
    }

    if (visitOutcome?.providerTypes) {
      return visitOutcome?.providerTypes.includes(p.providerType as any);
    }

    // Don't count MAs as "all providers" (indicated by visitOutcome.providerTypes === null)
    return p.providerType !== 'MA';
  });

  return (
    <View style={cssStyles.providers}>
      {physicians?.map(physician => {
        return <Provider physician={physician} key={physician.uuid} />;
      })}
    </View>
  );
};
