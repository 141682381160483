import React from 'react';
import { View } from 'react-native';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { MaterialIcon, PulseProvider, Select, SkeletonLoader, Text, VisitReasonIcon } from '@almond/ui';
import { useRouter } from 'expo-router';

import { useVisitReasons } from '../../hooks';
import styles, { unstable_styles as cssStyles } from './VisitReasonSwitcher.module.css';

import type { NewSelectOptionGroup } from '@almond/ui';
import type { Key } from 'react';

type VisitReasonSwitcherProps = {
  hideIcon?: boolean;
  disableSwitching?: boolean;
  onSelectionChange?: (reason: Key) => void;
};

export const VisitReasonSwitcher = (props: VisitReasonSwitcherProps) => {
  const { onSelectionChange } = props;
  // const [t] = useTranslation();
  const { setParams } = useRouter();
  const { visitReasons, visitReason, isLoadingVisitReasons } = useVisitReasons();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();

  const filteredVisitReasons =
    visitReasons
      ?.filter(vr => !vr.acuityBookingUrl && vr.isActive)
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(vr => ({
        value: vr.code,
        label: vr.title,
        group: vr.category,
      })) ?? [];

  const groupedVisitReasons = filteredVisitReasons.reduce(
    (acc, vr) => {
      const group = acc.find(a => a.label === vr.group);

      if (group) {
        group.options.push(vr);
      } else {
        acc.push({
          label: vr.group,
          labelHidden: vr.group === 'general',
          options: [vr],
        });
      }

      return acc;
    },
    [] as NewSelectOptionGroup<(typeof filteredVisitReasons)[number]>[]
  );

  if (!visitReasons || !visitReason) {
    return (
      <div className={styles.wrapper}>
        <PulseProvider duration={1000}>
          {!props.hideIcon && (
            <>
              <div className={styles.visitReasonIcon}>
                <SkeletonLoader.Circle diameter={40} />
              </div>
              <SkeletonLoader.RoundedRectangle roundness={4} height={20} width={100} />
            </>
          )}
        </PulseProvider>
      </div>
    );
  }

  const showReadOnlyContent = props.disableSwitching || filteredVisitReasons.length <= 1;
  const triggerContent = (
    <View style={cssStyles.currentVisitReason}>
      {!props.hideIcon && <VisitReasonIcon style={cssStyles.visitReasonIcon} visitReason={visitReason.code} />}
      <Text fontStyle="bold" size="m">
        {visitReason.title}
      </Text>
      {!showReadOnlyContent && !isLoadingVisitReasons && (
        <MaterialIcon source="keyboard-arrow-down" size={20} aria-hidden />
      )}
    </View>
  );

  if (showReadOnlyContent) {
    return <div className={styles.wrapper}>{triggerContent}</div>;
  }

  const defaultOnSelectionChange = (reason: Key) => {
    const updatedVisitReason = String(reason);

    setParams({ visit_reason: updatedVisitReason });
    trackAnalyticsEvent('visit_reasons', { visitReasons: updatedVisitReason });
  };
  const onSelectionChangeHandler = onSelectionChange || defaultOnSelectionChange;

  return (
    <div className={styles.wrapper}>
      <Select.Root value={visitReason.code} onValueChange={value => onSelectionChangeHandler(value)}>
        <Select.Trigger testID="VisitReasonSwitcherTrigger">{triggerContent}</Select.Trigger>
        <Select.OptionContainer options={groupedVisitReasons} testID="VisitReasonSwitcherPopover" position="popper" />
      </Select.Root>
    </div>
  );
};
