import { HttpRequestError } from '~modules/errors';

import * as axiosBase from '../axiosBase';
import { CALCULATE_PRICE_PATH, PRODUCTS_PATH, PROMOTION_CODES } from './config';

import type { ProductKeyEnum } from '@almond/api-types';
import type { PromotionCode, PromotionCodeResult } from '~types';

const lookupKeys = [
  'basic_annual_membership',
  'baseline',
  'virtual_membership',
  'menopause',
  'pcos',
  'maternity',
  'health_coaching',
];

export const getProducts = () => axiosBase.get(PRODUCTS_PATH, { params: { lookup_keys: lookupKeys } });

export const calculatePrice = (priceId: string) => axiosBase.post(CALCULATE_PRICE_PATH(priceId));

export const checkPromotionCode = async (code: string, product?: ProductKeyEnum): Promise<PromotionCode> => {
  const isFirstMonthFree = ['firstmonthfree', 'offer35'].some(promoCode => code.toLowerCase() === promoCode);

  if (code.toLowerCase() === 'firstmonthfree') {
    // Hardcode "fake" promotion code
    return {
      amountOff: 0,
      id: '',
      promotionCodeType: 'stripe_discount',
      code,
      state: 'success',
      isFirstMonthFree,
    } satisfies PromotionCodeResult;
  }

  try {
    const result = await axiosBase.get(PROMOTION_CODES, { params: { code: code.toLowerCase(), product } });

    return {
      ...result,
      code,
      state: 'success',
      isFirstMonthFree,
    };
  } catch (e) {
    if (!(e instanceof HttpRequestError)) {
      throw e;
    }

    if (e.status === 404) {
      return { code, state: 'not-found' };
    }

    if (e.status === 400) {
      return { code, state: 'not-applicable' };
    }

    throw e;
  }
};
