import type { PromotionCode } from '../api';
import type { InsuranceCarrierChoicesEnum } from '@almond/api-types';
import type { SelectInputValue } from '@almond/ui';

export type ProfileState = {
  uuid?: string;
  firstName?: string;
  preferredFirstName?: string;
  lastName?: string;
  birthday?: string;
  email?: string;
};

export type DemographicState = {
  firstName?: string;
  preferredFirstName?: string;
  lastName?: string;
  birthday?: string;
  email?: string;
  phone?: string;
  isOptedIntoSms?: boolean;
};

export type CreditCardState = {
  paymentMethod?: string;
  error?: string;
  promotionCode?: PromotionCode;
  friendReferralCode?: PromotionCode;
};

type InsuranceCard = {
  front?: string;
  back?: string;
};

export type InsuranceState = {
  carrier: SelectInputValue<InsuranceCarrierChoicesEnum>;
  id?: string;
  card: InsuranceCard;
};

export type ProfileProgressState = {
  isPricingPageViewed?: boolean;
  demographic?: boolean;
  creditCard?: boolean;
  isInsuranceAccepted?: boolean;
  hasInsuranceFrontCard?: boolean;
};
