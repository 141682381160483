import React from 'react';

import { useTranslation } from '@almond/localization';

import { InterstitialLayout } from '~modules/layout';
import { useRouteNavigation } from '~modules/routing';

import type { HISTORY_INTERSTITIAL_PAGE_NAME } from '~types';

export const HistoryInterstitialPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof HISTORY_INTERSTITIAL_PAGE_NAME>();

  return (
    <InterstitialLayout
      id="historyInterstitial"
      title={t('historyInterstitial.title')}
      description={t('historyInterstitial.description')}
      submitButtonProps={{
        onPress: () => {
          dispatch('submit');
        },
        children: t('historyInterstitial.submit'),
      }}
    />
  );
};
