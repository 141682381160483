export const ALLERGIES_PAGE_NAME = '/allergies' as const;
export const DOCTOR_INFO_PAGE_NAME = '/doctor-info' as const;
export const DRUG_ALLERGIES_PAGE_NAME = '/drug-allergies' as const;
export const FAMILY_HISTORY_PAGE_NAME = '/family-history' as const;
export const HEALTH_CONDITIONS_PAGE_NAME = '/health-conditions' as const;
export const HOSPITALIZATION_PAGE_NAME = '/hospitalization' as const;
export const MEDICATIONS_PAGE_NAME = '/medications' as const;
export const OCCUPATION_PAGE_NAME = '/occupation' as const;
export const PERNICIOUS_HABITS_PAGE_NAME = '/pernicious-habits' as const;
export const PREGNANCIES_PAGE_NAME = '/pregnancies' as const;
export const SURGERIES_PAGE_NAME = '/surgeries' as const;
export const PRIMARY_CARE_PROVIDER_PAGE_NAME = '/primary-care-provider' as const;
export const HISTORY_INTERSTITIAL_PAGE_NAME = '/health-history' as const;
export const NEXT_STEPS_PAGE_NAME = '/next-steps' as const;

export type HistoryParams = {
  [ALLERGIES_PAGE_NAME]: undefined;
  [DOCTOR_INFO_PAGE_NAME]: undefined;
  [DRUG_ALLERGIES_PAGE_NAME]: undefined;
  [FAMILY_HISTORY_PAGE_NAME]: undefined;
  [HEALTH_CONDITIONS_PAGE_NAME]: undefined;
  [HISTORY_INTERSTITIAL_PAGE_NAME]: undefined;
  [HOSPITALIZATION_PAGE_NAME]: undefined;
  [MEDICATIONS_PAGE_NAME]: undefined;
  [OCCUPATION_PAGE_NAME]: undefined;
  [PERNICIOUS_HABITS_PAGE_NAME]: undefined;
  [PREGNANCIES_PAGE_NAME]: undefined;
  [SURGERIES_PAGE_NAME]: undefined;
  [PRIMARY_CARE_PROVIDER_PAGE_NAME]: undefined;
  [NEXT_STEPS_PAGE_NAME]: undefined;
};
