import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, HOME_SITE_HREF, Text, useTheme } from '@almond/ui';
import { useRecoilValue } from 'recoil';

import { useRedirectBackToArtemis } from '~modules/integration';
import { patientAtom, patientProgressAtom, userAtom, useWaitlistModalState } from '~modules/state';

import { themedStyles } from './styles';

type WaitlistModalCompleteProps = {
  onClose: () => void;
};

export const WaitlistModalComplete = (props: WaitlistModalCompleteProps) => {
  const { onClose } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();
  const { backToArtemisLinkProps } = useRedirectBackToArtemis();
  const { isNewMember } = useRecoilValue(patientAtom);
  const { isAdmin } = useRecoilValue(userAtom);
  const { subscriptionActive } = useRecoilValue(patientProgressAtom);
  const [waitlistState] = useWaitlistModalState();

  const backToDashboard = !isNewMember || isAdmin;
  const secondaryButtonProps = backToDashboard ? backToArtemisLinkProps() : { href: HOME_SITE_HREF };

  return (
    <View style={styles.completeContainer}>
      <View style={styles.headerContainer}>
        <Text size="xxl" fontStyle="medium" style={styles.title}>
          {t('scheduling.waitlistModal.completeTitle')}
        </Text>
        <Text size="s" style={styles.subtitle}>
          {t(
            subscriptionActive
              ? 'scheduling.waitlistModal.completeAndMembershipSubtitle'
              : 'scheduling.waitlistModal.completeSubtitle'
          )}
        </Text>
      </View>
      <View style={styles.submitButtonContainer}>
        <Button style={styles.submitButton} onPress={onClose} testID="WaitlistModal-CompleteButton">
          {waitlistState.isOnSchedulingPage
            ? t('scheduling.waitlistModal.completeButton')
            : t('scheduling.waitlistModal.completeButtonNotOnScheduling')}
        </Button>
        <Button style={styles.submitButton} type="secondary" {...secondaryButtonProps}>
          {t(backToDashboard ? 'takeMeHome' : 'backToWebsite')}
        </Button>
      </View>
    </View>
  );
};
