import React from 'react';

import { Redirect, useLocalSearchParams } from 'expo-router';

// `/tell_us_more` link is in emails sent to members, so even if
// we change the email content away from this link, we'll need to
// keep this redirect until the farthest-in-the-future appt confirmed
// with an email with a `/tell_us_more` link has been completed.

// NOTE this is different than the free text response page - tell-us-more
// This route has underscores, that route has dashes
export default () => {
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);

  return <Redirect href={`/login?${searchParams}`} />;
};
