/* eslint-disable @typescript-eslint/naming-convention */

type GooglePlacesValues = {
  PLACES_API_KEY: string;
};

type IntegrationValues = {
  ACUITY_SCHEDULING_EMBED: string;
  SEGMENT_WRITE_KEY: string;
  GTM_KEY: string;
  STATSIG_CLIENT_ID: string;
  STATSIG_ENVIRONMENT: string;
  MERCHANT_ID: string;
};

type SharedEnv = GooglePlacesValues;

type Auth0ManagementValues = {
  AUTH0_MANAGEMENT_DOMAIN: string;
  AUTH0_MANAGEMENT_AUDIENCE: string;
  AUTH0_MANAGEMENT_CLIENT_ID: string;
};

type Auth0Values = Auth0ManagementValues & {
  AUTH0_BASE_URL: string;
  AUTH0_DOMAIN: string;
  AUTH0_AUDIENCE: string;
  AUTH0_CLIENT_ID: string;
};

export type Env = SharedEnv &
  Auth0Values &
  IntegrationValues & {
    API_BASE_URL: string;
    APEX_DOMAIN: string;
    ARTEMIS_FALLBACK_URL: string;
    STRIPE_API_KEY: string;
    APPLE_PAY_MANAGEMENT_URL: string;
  };

type CypressEnv = Auth0Values &
  Pick<Env, 'ARTEMIS_FALLBACK_URL'> & {
    API_BASE_URL: string;
    AUTH0_USERNAME_LOCAL_PART: string;
    AUTH0_USERNAME_DOMAIN: string;
    AUTH0_PASSWORD: string;
    AUTH0_ADMIN_USERNAME: string;
    AUTH0_ADMIN_PASSWORD: string;
    AUTH0_RETURNED_PATIENT_USERNAME: string;
    AUTH0_PATIENT_NO_SIGNATURE_USERNAME: string;
    RETURNED_MEMBER_DOB: string;
    NO_SIGNATURE_MEMBER_DOB: string;
    RETURNED_PATIENT_UUID: string;
    NO_SIGNATURE_PATIENT_UUID: string;
  };

const sharedEnv: SharedEnv = {
  PLACES_API_KEY: 'AIzaSyB3LvK51xnyAfyyRXHS7_yLP_Rh1IC69hA',
};

export const stageEnv: Env = {
  ...sharedEnv,
  ACUITY_SCHEDULING_EMBED: 'https://app.acuityscheduling.com/schedule.php?owner=23864372&appointmentType=60391901',
  API_BASE_URL: 'https://api.stage-almondobgyn.com/',
  APEX_DOMAIN: '.stage-almondobgyn.com',
  AUTH0_BASE_URL: 'https://auth.stage-almondobgyn.com',
  AUTH0_DOMAIN: 'auth.stage-almondobgyn.com',
  AUTH0_CLIENT_ID: 'Gr196YOvuJZ8Jhb82jCNoGyWW8HjGl5l',
  AUTH0_MANAGEMENT_DOMAIN: 'almond-health-stage.us.auth0.com',
  AUTH0_AUDIENCE: 'almond-patient-portal',
  AUTH0_MANAGEMENT_AUDIENCE: 'https://almond-health-stage.us.auth0.com/api/v2/',
  AUTH0_MANAGEMENT_CLIENT_ID: 'cIqzOFkmZDJr3m7tFY92GJrhDmi2qxAH',
  SEGMENT_WRITE_KEY: 'STAGE_ENV_WRITE_KEY',
  GTM_KEY: 'GTM-STAGE-ENV',
  ARTEMIS_FALLBACK_URL: 'https://app.stage-almondobgyn.com',
  STATSIG_CLIENT_ID: 'client-CHX3a0jBCoHP56XZz4NE8cRiPwxal6v57XPfgGIteFM',
  STATSIG_ENVIRONMENT: 'staging',
  MERCHANT_ID: 'MERCHANT_ID',
  STRIPE_API_KEY:
    'pk_test_51HUdMBFwnGDnHk8SRvfudIn2iz2Pwi6uBn2NU7FMPvtkANJwFCrV0DvFsk1NHP9c6zGjREEHp9Bvw2Y1oJFZq9O000P0mlUZWh',
  APPLE_PAY_MANAGEMENT_URL: 'https://billing.stripe.com/p/login/test_eVa8xq59daMZ4BWbII',
};

export const devEnv: Env = {
  ...stageEnv,
  ARTEMIS_FALLBACK_URL: 'https://local.stage-almondobgyn.com:3000',
  STATSIG_ENVIRONMENT: 'development',
};

export const prodEnv: Env = {
  ...sharedEnv,
  ACUITY_SCHEDULING_EMBED: 'https://almondhealth.as.me/?appointmentType=60391979',
  API_BASE_URL: 'https://api.almondobgyn.com/',
  APEX_DOMAIN: '.almondobgyn.com',
  AUTH0_BASE_URL: 'https://auth.almondobgyn.com',
  AUTH0_DOMAIN: 'auth.almondobgyn.com',
  AUTH0_CLIENT_ID: 'tqNEZGUTeOu6jKX9qODH3SmdO7PBzNZX',
  AUTH0_MANAGEMENT_DOMAIN: 'almond-health.us.auth0.com',
  AUTH0_AUDIENCE: 'almond-patient-portal',
  AUTH0_MANAGEMENT_AUDIENCE: 'https://almond-health.us.auth0.com/api/v2/',
  AUTH0_MANAGEMENT_CLIENT_ID: 'V0W5P80ZqJocfode6ivVzBsCCXyOGj51',
  SEGMENT_WRITE_KEY: 'RbNtFK1BxstGvvq04KqdUybB8KFqgao6',
  GTM_KEY: 'GTM-MV243PZ',
  ARTEMIS_FALLBACK_URL: 'https://app.almondobgyn.com',
  STATSIG_CLIENT_ID: 'client-DTv9oJ14MEwRz9gC7PwxdyQfdfoW99WRgV75FBdx0wr',
  STATSIG_ENVIRONMENT: 'production',
  MERCHANT_ID: '152055',
  STRIPE_API_KEY:
    'pk_live_51HUdMBFwnGDnHk8Sa6uN2xyTphFef5gv38vPy43tGzcWCeKJo1tCsdbsn2TUdSUmHkNB4flhTRP0XNr6YRC2OeyL009pzEBRWe',
  APPLE_PAY_MANAGEMENT_URL: 'https://billing.stripe.com/p/login/6oE02F4igeXo3M4288',
};

export const cypressDevEnv: CypressEnv = {
  API_BASE_URL: 'https://api.stage-almondobgyn.com/',
  AUTH0_BASE_URL: devEnv.AUTH0_BASE_URL,
  AUTH0_DOMAIN: devEnv.AUTH0_DOMAIN,
  AUTH0_AUDIENCE: devEnv.AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: devEnv.AUTH0_CLIENT_ID,
  AUTH0_MANAGEMENT_DOMAIN: devEnv.AUTH0_MANAGEMENT_DOMAIN,
  AUTH0_MANAGEMENT_AUDIENCE: devEnv.AUTH0_MANAGEMENT_AUDIENCE,
  AUTH0_MANAGEMENT_CLIENT_ID: devEnv.AUTH0_MANAGEMENT_CLIENT_ID,
  AUTH0_USERNAME_LOCAL_PART: 'cypress.dev',
  AUTH0_USERNAME_DOMAIN: 'almondobgyn.com',
  AUTH0_ADMIN_USERNAME: 'test_careguide@almondobgyn.com',
  AUTH0_ADMIN_PASSWORD: 'wW7wMdE3RW2LTCNh',
  AUTH0_RETURNED_PATIENT_USERNAME: 'cypress.dev.returnedpatient+new@almondobgyn.com', // needs to be lower case
  AUTH0_PATIENT_NO_SIGNATURE_USERNAME: 'cypress.dev.nosignature+new@almondobgyn.com',
  AUTH0_PASSWORD: 'nXWhhG2X',
  ARTEMIS_FALLBACK_URL: devEnv.ARTEMIS_FALLBACK_URL,
  RETURNED_MEMBER_DOB: '09/02/1945',
  NO_SIGNATURE_MEMBER_DOB: '11/11/1918',
  RETURNED_PATIENT_UUID: 'a1cea4ef-4f41-4ab8-955c-565c9d43d16b',
  NO_SIGNATURE_PATIENT_UUID: 'bd2814e0-b2d9-47d4-88ff-29b7fd4835b6',
};

// eslint-disable-next-line import/no-mutable-exports
let env: Env = devEnv;

if (process.env.EXPO_PUBLIC_API_ENV === 'local') {
  env.API_BASE_URL = 'https://localhost:8000/';
}

if (process.env.EXPO_PUBLIC_ENV === 'prod') {
  env = prodEnv;
} else if (process.env.EXPO_PUBLIC_ENV === 'stage') {
  env = stageEnv;
}

export default env;
