import { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { profilesApi } from '~modules/api';
import { HttpRequestError } from '~modules/errors';
import { patientProgressAtom, profileAtom } from '~modules/state';

import { logAndCapture } from '../logger';

import type { FormValues } from '@almond/ui';
import type { AddressFormValues } from '~types';

const useCreateAddress = (): ((values: FormValues<AddressFormValues>) => Promise<void>) => {
  const profileState = useRecoilValue(profileAtom);
  const setPatientProgressState = useSetRecoilState(patientProgressAtom);
  const { t } = useTranslation();

  return useCallback(
    async (values: FormValues<AddressFormValues>) => {
      if (!profileState.uuid) {
        return logAndCapture("profile uuid isn't specified, so the address can't be added.");
      }

      try {
        await profilesApi.createAddress(profileState.uuid)(values);
        setPatientProgressState(prevState => ({ ...prevState, address: true }));
      } catch (error) {
        if (error instanceof HttpRequestError && error.status === 400) {
          throw new Error(t('addressRequiredError'));
        } else {
          throw error;
        }
      }
    },
    [profileState.uuid, t, setPatientProgressState]
  );
};

export default useCreateAddress;
