import React from 'react';
import { View } from 'react-native';

import { Button, Text } from '@almond/ui';

import { SANDBOX_RESPONSE_ID } from '../../config';

import type { WidgetProps } from '@typeform/embed-react';

export const MockWidget = (props: WidgetProps) => {
  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', gap: 16 }}>
      <Text testID={`MockWidget:${props.id}`}>{`Click submit to simulate a typeform response`}</Text>
      <Button
        testID="Submit"
        onPress={event => {
          event.preventDefault();

          // If the dataset.responseId (data-response-id attribute) is present on the clicked anchor
          // element, use it as the responseId. This is useful for Cypress to mock a responseId
          // to then verify gets sent to the server when completing the booking.
          const responseId =
            event.target instanceof HTMLAnchorElement && event.target.dataset.responseId
              ? event.target.dataset.responseId
              : SANDBOX_RESPONSE_ID;

          props.onSubmit?.({ formId: props.id, responseId });
        }}
      >
        {'Submit'}
      </Button>
    </View>
  );
};
