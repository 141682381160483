import type { PatientProductOut, ProductKeyEnum, ProductOut } from '@almond/api-types';

function isProductChecker<P extends ProductKeyEnum | ProductKeyEnum[]>(productKey: P) {
  function isProduct(product: string | undefined | null): product is P extends ProductKeyEnum[] ? P[number] : P;
  function isProduct(product: ProductOut | undefined | null): boolean;
  function isProduct(product: ProductOut | string | undefined | null) {
    const passedProductKey = typeof product === 'string' ? product : product?.almondProductKey;

    return Array.isArray(productKey)
      ? productKey.includes(passedProductKey as ProductKeyEnum)
      : passedProductKey === productKey;
  }

  return isProduct;
}

export const isBaselineProduct = isProductChecker('baseline');
export const isVirtualMembershipProduct = isProductChecker('virtual_membership');
export const isMembershipProduct = isProductChecker(['virtual_membership', 'gynecology_membership']);

export function hasBaselineProduct(products: readonly PatientProductOut[] | undefined): boolean {
  if (!products) {
    return false;
  }

  return products.some(product => isBaselineProduct(product.almondProductKey));
}
