import * as axiosBase from '../axiosBase';
import {
  APPOINTMENT_PATH,
  APPOINTMENTS_PATH,
  BOOKING_PATH,
  FUTURE_APPOINTMENTS_PATH,
  LAB_SUBMIT_PATH,
  PAST_COUNT_PATH,
} from './config';

import type { TagEnum } from '@almond/api-types';

export const createAppointment = axiosBase.post(APPOINTMENTS_PATH);

export const createAppointmentBooking = (
  appointmentUuid: string,
  skipConfirmationMessages: boolean,
  extraTags: TagEnum[] | undefined = undefined
) => axiosBase.post(BOOKING_PATH(appointmentUuid), { timeout: 16000 })({ skipConfirmationMessages, extraTags });

export const getAppointment = (appointmentUuid: string) => axiosBase.get(APPOINTMENT_PATH(appointmentUuid));

export const submitLabOrders = (appointmentUuid: string) => axiosBase.post(LAB_SUBMIT_PATH(appointmentUuid));

export const getPastAppointmentCount = (patientUuid: string) => axiosBase.get(PAST_COUNT_PATH(patientUuid));

export const getFutureAppointments = () => axiosBase.get(FUTURE_APPOINTMENTS_PATH);
