import React from 'react';

import { ErrorMessage } from '~modules/errors';
import { NavigationBar } from '~modules/integration';
import { Background } from '~modules/ui/components';

export const IntakeFormNotFound = () => {
  return (
    <Background>
      <NavigationBar />
      <ErrorMessage
        error={new Error('Form not found. Please contact your care team if you believe this is an error.')}
        isFull={false}
      />
    </Background>
  );
};
